import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'app/services/http.service';
import { Instance } from 'app/models/control-center/instance.model';
import { Company } from 'app/models/control-center/company.model';
import { MatDialog } from '@angular/material/dialog';
import { Permission } from 'app/models/control-center/permission.model';
import { Person } from 'app/models/control-center/person.model';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-orphaned-instances',
  templateUrl: './orphanedInstances.component.html',
  styleUrls: ['./orphanedInstances.component.scss'],
})
export class OrphanedInstancesComponent implements OnInit {
  loading = true;
  showErrorMessage = false;
  permissions: Permission;

  orphanedInstances: Instance[];
  instances: Instance[];
  instanceId: number;
  companies: Company[];
  companyId: number;
  assingedCompanyId: number;
  people: Person[];

  selectedInstances: Instance[];

  displayedColumns: any = [
    { key: 'name', label: 'Name', type: 'string' },
    { key: 'activated', label: 'Activated', type: 'boolean' },
    { key: 'lastActivityDate', label: 'Last Activity', type: 'date' },
  ];

  tableActions = [
    { name: 'Assign', key: 'assign', requireItemSelection: true },
    { name: 'Merge', key: 'merge', requireItemSelection: true },
    { name: 'Activate', key: 'activate', requireItemSelection: 1, show: (rows: any[]) => rows?.every(r => !r.activated) },
  ];

  rowActions = [
    { name: 'Activate', key: 'activate', color: 'primary', show: (row: any) => !row?.activated },
  ];

  @ViewChild('assignDialog') assignDialog: any;
  @ViewChild('mergeDialog') mergeDialog: any;
  @ViewChild('activateDialog') activateDialog: any;

  instanceAdminForm: FormGroup = this._formBuilder.group({
    administratorPersonId: this._formBuilder.control(null, [Validators.required]),
  });

  constructor(
    private _httpService: HttpService,
    private _dialog: MatDialog,
    private _formBuilder: FormBuilder,
  ) { }

  get administratorPersonIdControl(): AbstractControl { return this.instanceAdminForm?.get('administratorPersonId'); }
  get administratorPersonIdValue(): number { return this.administratorPersonIdControl?.value; }

  ngOnInit(): void {
    this.load();
  }

  async load() {
    this.loading = true;
    await Promise.all([
      (async () => this.orphanedInstances = await this._httpService.get('administration/orphanedInstances'))(),
      (async () => this.instances = await this._httpService.get(`companies/instances`))(),
      (async () => this.companies = await this._httpService.get(`companies`))(),
      (async () => this.people = await this._httpService.get(`people`))(),
      (async () => this.permissions = await this._httpService.get(`people/authenticated/permissions`))(),
    ]);

    this.loading = false;
  }

  showAssign(ids: number[]) {
    const dialogRef = this._dialog.open(this.assignDialog);
    dialogRef.afterClosed().subscribe(async result => {
      if (result !== undefined) {
        if (result === 'save') {
          this.loading = true;
          this.showErrorMessage = false;
          try {
                await this._httpService.post('administration/assignOrphanedInstances', { ids: ids, companyId: this.companyId });
                this.companyId = null;
                await this.load();
          } catch (e) {
                console.error('Failed to assign orphaned instance', e);
                this.showErrorMessage = true;
          }
        }
      }
    });
  }

  showMerge(ids: number[]) {
    const dialogRef = this._dialog.open(this.mergeDialog);
    dialogRef.afterClosed().subscribe(async result => {
      if (result !== undefined) {
        if (result === 'save') {
          this.showErrorMessage = false;
          this.loading = true;
          try {
                await this._httpService.post('administration/mergeInstances', { ids: ids, instanceId: this.instanceId });
                this.instanceId = null;
                await this.load();
          } catch (e) {
                console.error('Failed to merge orphaned instance', e);
                this.showErrorMessage = true;
          }
        }
      }
    });
  }

  async handleTableAction(event: { key: string, ids: number[] }) {
    const { key, ids } = event;
    switch (key) {
      case 'assign':
        this.showAssign(ids);
        break;
      case 'merge':
        this.showMerge(ids);
        break;
      case 'activate':
        await this.handleTableRowAction({
          key: 'activate',
          rows: this.orphanedInstances.filter(i => ids.includes(i.id)),
        });
        break;
    }
  }

  async handleTableRowAction(event: { key: string, rows: any[] }) {
    const { key, rows } = event;
    this.selectedInstances = rows;
    switch (key) {
      case 'assign':
        break;
      case 'merge':
        break;
      case 'activate':
        this.administratorPersonIdControl.reset();
        this._dialog.open(this.activateDialog)
          .afterClosed()
          .subscribe(async action => {
            switch (action) {
              case 'save':
                this.showErrorMessage = false;
                this.loading = true;
                try {
                      await this._httpService.post('companies/instances/activate', {
                        person: this.administratorPersonIdValue,
                        instanceIds: rows.map(r => r.id),
                      });
                      this.orphanedInstances = await this._httpService.get('administration/orphanedInstances');
                      this.loading = false;
                } catch (e) {
                      console.error('Failed to activate orphaned instance', e);
                      this.showErrorMessage = true;
                }
                break;
            }
          });
        break;
    }

  }
}
