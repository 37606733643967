import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Feature } from '@weavix/models/src/features/feature';
import { HttpService } from 'app/services/http.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-features',
    standalone: false,
    templateUrl: './features.component.html',
    styleUrl: './features.component.scss',
})
export class FeaturesComponent implements OnInit {
    @ViewChild('updateDialog') updateDialog: TemplateRef<unknown>;

    loading = true;
    /** The features listed in the table */
    dataSource: Feature[] = [];
    /** Represents the table row that was clicked. */
    selectedFeature: Feature;
    /** Holds the value for the checkbox displayed in the feature modal */
    enabledCheckboxValue = false;
    /** Keeps the count of feature flags for facilities*/
    usage: Array<{ accountName: string, facilities: object, accountSetting?: boolean }>;

    displayedColumns: any = [
        {
            key: 'name',
            label: 'Feature',
            type: 'string',
            value: (row: Feature) => row?.name,
        },
        {
            key: 'description',
            label: 'Description',
            type: 'string',
            value: (row: Feature) => row?.description,
        },
        {
            key: 'lastUpdated',
            label: 'Last Updated',
            type: 'date',
            value: (row: Feature) => row?.lastUpdated,
        },
        {
            key: 'defaultValue',
            label: 'Default Value',
            type: 'string',
            value: (row: Feature) => row?.defaultValue ? 'Enabled' : 'Disabled',
        },
    ];

    constructor(private httpService: HttpService, private dialog: MatDialog) {
        this.ngOnInit();
    }

    async ngOnInit() {
        this.loading = true;
        try {
            this.dataSource = await this.loadFeatures();
        } catch (e) {
            console.error('Failed to load features', e);
        } finally {
            this.loading = false;
        }
    }

    async loadFeatures() {
        try {
            return await this.httpService.get(`companies/weavix-account/features`);
        } catch (e) {
            alert('Failed to load features');
            console.error('Failed to load features', e);
            return [];
        }
    }

    public async onRowClick(featureId: string) {
        this.selectedFeature = this.dataSource.find((f) => f.id === featureId);
        this.enabledCheckboxValue = this.selectedFeature.defaultValue;
        this.dialog.open(this.updateDialog);
        const result = await this.httpService.get(`companies/weavix-account/features/${featureId}`);

        this.usage = Object.values(result);
    }

    public getObjectPropertyCount(object: object, value: boolean) {
        return Object.values(object).filter((v) => v === value).length;
    }

    public async save() {
        this.loading = true;
        try {
            await this.httpService.put(`companies/weavix-account/features/${this.selectedFeature.id}`, {
                enabledByDefault: this.enabledCheckboxValue,
            });
            this.dialog.closeAll();
            await this.ngOnInit();
        } catch (e) {
            alert('Failed to update feature');
            console.error('Failed to update feature', e);
        } finally {
            this.loading = false;
        }
    }
}
