import { HttpErrorResponse } from '@angular/common/http';
import { Component, computed, inject, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PermissionType } from 'app/models/control-center/permission.model';
import { InstanceService } from 'app/services/instance.service';
import { PermissionService } from 'app/services/permission.service';
import { distinctUntilChanged, from, map, of, switchMap, tap } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-instance',
    templateUrl: './instance.component.html',
    styleUrls: ['./instance.component.scss'],
})
export class InstanceComponent {
    private readonly route = inject(ActivatedRoute);
    private readonly instanceService = inject(InstanceService);
    private readonly permissionService = inject(PermissionService);

    readonly loadingInstance = signal(false);
    readonly loadingTab = signal(false);
    readonly selectedIndex = signal(0);
    readonly errorMessage = signal<string | null>(null);

    readonly instance = toSignal(this.route.paramMap.pipe(
        map(params => parseInt(params.get('id') ?? '0', 10)),
        distinctUntilChanged(),
        tap(() => this.loadingInstance.set(true)),
        switchMap(instanceId => instanceId ? this.instanceService.getById(instanceId) : of(null)),
        tap(() => this.loadingInstance.set(false)),
    ), { initialValue: null });

    // We need companyId separately from instance.companyId because we could be creating a new instance.
    readonly companyId = toSignal(this.route.queryParamMap.pipe(
        map(params => parseInt(params.get('companyId') ?? '0', 10)),
        distinctUntilChanged(),
    ));

    readonly permissions = toSignal(from(this.permissionService.getMyPermissions()));

    readonly hasSupportAccess = computed(() => this.permissions().SupportAccess > PermissionType.None);
    readonly hasTwoWaltDemoEdit = computed(() => this.permissions().TwoWaltDemo > PermissionType.Read);
    readonly hasFeatureFlags = computed(() => this.permissions().FeatureFlags > PermissionType.None);
    readonly isExistingInstance = computed(() => !!this.permissions && !!this.instance());

    handleError(err: unknown) {
        if (!err || err === false) {
            this.errorMessage.set(null);
            return;
        }
        this.errorMessage.set('Please refresh and try again.');

        if (!(err instanceof HttpErrorResponse)) return;

        if (err.error['details']?.['reason']) {
            this.errorMessage.set(this.handleKnownReasons(err.error['details']['reason']));
        } else if (err.error['message']) {
            this.errorMessage.set(err.error['message']);
        } else {
            this.errorMessage.set(err.message);
        }
    }

    private handleKnownReasons(reason: string): string {
        switch (reason) {
            case 'unknown_support_user':
                return 'User is unknown to weavix.';
            case 'user_already_in_account':
                return 'User is already added to this account and cannot be added again.';
            case 'session_disconnected':
                return 'Session has already been disconnected.';
            default:
                return reason;
        }
    }
}
