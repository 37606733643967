<ng-template #modalContent>
    <div class="d-flex flex-row justify-content-between align-items-start">
        <h2 matDialogTitle>{{titleText}}</h2>
        <button type="button" mat-icon-button class="icon-button-small" (click)="closeDialog()">
            <mat-icon aria-hidden="false" aria-label="Close icon">close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <p>{{ titleText }}</p>
        <p>This includes networks saved by the user. Any Wi-Fi networks currently added to console will be updated after.</p>
        <p>Do you want to proceed?</p>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <div class="d-flex flex-row justify-content-between gap-3 w-100">
            <button
                mat-button
                (click)="closeDialog()"
                [disabled]="isSubmitting"
            >Cancel</button>
            <button
                mat-raised-button
                (click)="dialogSubmit()"
                
                color="primary"
                [disabled]="isSubmitting"
            >
                <div class="d-flex flex-row justify-content-center align-items-center gap-2">
                    <span>Yes</span>
                    <mat-spinner *ngIf="isSubmitting" diameter="20"/>
                </div>
            </button>
        </div>
    </mat-dialog-actions>
</ng-template>
