<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-3">
    <h1 class="h2">Consumable: <span *ngIf="consumable">{{consumable.name}}</span></h1>
</div>

<div *ngIf="loading">loading</div>

<div *ngIf="!loading">
    <div class="row">
        <div class="col-md-12">
            <form class="needs-validation" novalidate="">
                <div class="row">
                    <div class="col-md-6 mb-3">
                        <mat-form-field appearance="fill" style="width:100%">
                            <mat-label>Name</mat-label>
                            <input matInput placeholder="name" name="name" [(ngModel)]="consumable.name">
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 mb-3">
                        <div class="col-md-3 mb-3">
                            <mat-form-field appearance="fill" style="width:100%">
                                <mat-label>Unit of Measure</mat-label>
                                <mat-select [(value)]="consumable.unitOfMeasure">
                                    <mat-option value="Each">Each</mat-option>
                                    <mat-option value="Linear Foot">Linear Foot</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <h3>Consumable Stock</h3>
                    <app-table [dataSource]="stock" [displayedColumns]="displayedColumns" (createClick)="addStock()"
                        (rowClick)="editStock($event)" (deleteClick)="deleteStock($event)" *ngIf="consumableId !== 0"
                        [permission]="permissions.ConsumableStock" uniqueKey="consumable-stock-{{consumable.name}}"></app-table>
                    <p *ngIf="consumableId === 0">Please save the new Asset Type first to create Asset Models</p>
                </div>
                <div class="row mt-5">
                    <div class="col-md-6 mb-3">
                        <button mat-raised-button class="demo-button" color="primary" (click)="save()">
                            Save
                        </button>
                        &nbsp;
                        <button mat-raised-button class="demo-button" [routerLink]="'/inventory/consumables'">
                            Cancel
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>


<ng-template #stockDialog>
    <h2 matDialogTitle>Stock</h2>
    <mat-dialog-content>
        <mat-form-field appearance="fill" style="width:100%">
            <mat-label>Quantity</mat-label>
            <input matInput placeholder="Quantity" name="stockQuantity" type="number" [(ngModel)]="stockModel.quantity">
        </mat-form-field>
        <mat-form-field appearance="fill" style="width:100%">
            <mat-label>Warehouse</mat-label>
            <mat-select [(value)]="stockModel.warehouseId">
                <mat-option *ngFor="let warehouse of warehouses" [value]="warehouse.id">
                    {{warehouse.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field style="width:100%" appearance="fill">
            <mat-label>Warehouse Location</mat-label>
            <input matInput placeholder="Warehouse Location" name="warehouseLocation" [(ngModel)]="stockModel.warehouseLocation">
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button matDialogClose="cancel">Cancel</button>
        <button mat-button matDialogClose="save">Save</button>
    </mat-dialog-actions>
</ng-template>
