import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpService } from 'app/services/http.service';

@Component({
    selector: 'app-delete-walt-saved-wifis-modal',
    templateUrl: './delete-walt-saved-wifis-modal.component.html',
    styleUrls: ['./delete-walt-saved-wifis-modal.component.scss'],
})
export class DeleteWaltSavedWifisModalComponent implements OnChanges {
    @Input() open = false;
    @Input() instanceId: number;
    @Input() siteId: number;
    @Input() waltId: number;

    @ViewChild('modalContent') modalContent: any;

    @Output() loadingChanged = new EventEmitter(false);
    @Output() closeEvent = new EventEmitter();
    @Output() errorEvent = new EventEmitter(null);

    isSubmitting = false;
    dialogRef: MatDialogRef<DeleteWaltSavedWifisModalComponent>;

    constructor(
        private _dialog: MatDialog,
        private _snackBar: MatSnackBar,
        private _httpService: HttpService,
    ) { }

    get titleText(): string {
        return this.waltId ? 'Performing this action will delete ALL previously saved Wi-Fi networks from this walt.'
            : 'Performing this action will delete ALL previously saved Wi-Fi networks from walts that are assigned to this site.';
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('open' in changes) {
            if (this.open) {
                this.openDialog();
            } else {
                this.closeDialog();
            }
        }
    }

    private openDialog() {
        this.dialogRef = this._dialog.open(this.modalContent, { id: 'delete-saved-wifi' });
        this.dialogRef.backdropClick().subscribe(() => this.closeDialog());
    }

    async dialogSubmit() {
        this.loadingChanged.emit(true);
        this.isSubmitting = true;
        try {
            const clearWaltSavedWifiRequest = {
                instanceId: this.instanceId?.toString(),
                siteId: this.siteId?.toString(),
                waltId: this.waltId?.toString(),
            };
            await this._httpService.post(`administration/clear-walt-saved-wifis`, clearWaltSavedWifiRequest);
        } catch (error) {
            this.errorEvent.emit(error);
            this._snackBar.open(`Failed to delete saved walt wifis: ${error.message}`, 'Dismiss', { duration: 10000 });
        } finally {
            this.loadingChanged.emit(false);
            this.isSubmitting = false;
            this.closeDialog();
        }
    }

    closeDialog() {
        this.dialogRef?.close();
        this.closeEvent.emit();
    }
}
