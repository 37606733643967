import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NonNullableFormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Instance } from 'app/models/control-center/instance.model';
import { Person } from 'app/models/control-center/person.model';
import { PersonListItem } from 'app/services/people.service';
import { CreateSessionRequest } from 'app/services/support-access.service';
import { environment } from 'environments/environment';

interface InstanceSupportAccessForm {
  user: FormControl<PersonListItem | string>;
  hours: FormControl<number>;
  externalTicketNumber: FormControl<string | null>;
  reason: FormControl<string>;
}

function isNotStringValidator(): ValidatorFn {
  return (control: FormControl<PersonListItem | string>): ValidationErrors | null => {
    const value = control.value;
    if (typeof value === 'string') {
      return { isNotString: false };
    }
    return null;
  };
}

function isSyncedWithWeavixValidator(): ValidatorFn {
  return (control: FormControl<PersonListItem | string>): ValidationErrors | null => {
    const value = control.value;
    if (typeof value === 'string') {
      return null; // Handled by other validator.
    }
    if (!value.weavixUserId) {
      return { isSyncedWithWeavix: false };
    }
    return null;
  };
}

@Component({
  selector: 'app-instance-support-access-user-form',
  templateUrl: './instance-support-access-user-form.component.html',
  styleUrls: ['./instance-support-access-user-form.component.scss'],
})
export class InstanceSupportAccessUserFormComponent implements OnInit {
  @ViewChild('confirmDialog') confirmDialog?: TemplateRef<unknown>;
  @Input() instance!: Instance;
  @Input() me?: Person;
  @Input() people: PersonListItem[] = [];
  @Output() submitClicked = new EventEmitter<CreateSessionRequest>();

  isLowerEnv = !environment.production;
  supportAccessForm: FormGroup<InstanceSupportAccessForm>;

  constructor(
    private readonly fb: NonNullableFormBuilder,
    private readonly dialog: MatDialog,
  ) {
    this.supportAccessForm = this.fb.group({
      user: this.fb.control<PersonListItem | string>('', [Validators.required, isNotStringValidator(), isSyncedWithWeavixValidator()]),
      hours: this.fb.control(1, [Validators.required, Validators.min(Number.MIN_VALUE)]),
      externalTicketNumber: this.fb.control<string | null>(null),
      reason: this.fb.control('', Validators.required),
    });
  }

  ngOnInit() {
    if (this.people.length && this.me) {
      const myPerson = this.people.find(p => p.id === this.me.id);
      if (myPerson) {
        this.supportAccessForm.controls.user.setValue(myPerson);
      }
    }
  }

  showConfirmation(formDirective: FormGroupDirective) {
    if (!this.confirmDialog) return;

    const values = this.supportAccessForm.value;

    this.dialog.open(this.confirmDialog, {
      data: {
        instanceName: this.instance.name,
        hours: values.hours,
        reason: values.reason,
      },
      maxWidth: '50vw',
    }).afterClosed()
      .subscribe((doSave: boolean) => {
        if (!doSave) return;

        this.submitClicked.emit({
          supportPersonId: (values.user as PersonListItem).id,
          reason: values.reason,
          externalTicket: values.externalTicketNumber,
          hours: values.hours,
        });
        formDirective.resetForm();
      });
  }
}
