<div class="d-flex flex-row justify-content-between align-items-start">
    <h2 matDialogTitle>{{'Delete Person - ' + person()?.name}}</h2>
    <button type="button" mat-icon-button (click)="onCancel()" class="close-button">
        <mat-icon aria-hidden="false" aria-label="Close icon">close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <span>Are you sure you want to delete {{person()?.name}} from weavix?</span>
    <br/>
    <span class="font-bold">NOTE: THIS ACTION CANNOT BE UNDONE!</span>
    <br/>
    <br/>
    <span *ngIf="!instances()?.length">User is not on any instances.</span>
    <span *ngIf="instances()?.length">
        User is on the following instance(s): {{instanceList()}}
    </span>
    <br/>
    <br/>
    <div class="delete-container">
        <span class="description">Type 'DELETE' to continue:</span>
        <input matInput [ngModel]="deleteText()" (ngModelChange)="deleteText.set($event)" class="delete-text-box" placeholder="DELETE">
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
        <button
            mat-raised-button
            (click)="onCancel()"
        >
            CANCEL
        </button>
        <button
            mat-raised-button
            (click)="onSubmit()"
            color="primary"
            [disabled]="deleteText() !== 'DELETE'"
        >
            DELETE
        </button>
</mat-dialog-actions>
