import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Company } from 'app/models/control-center/company.model';
import { Instance } from 'app/models/control-center/instance.model';
import { Person } from 'app/models/control-center/person.model';
import { HttpService } from 'app/services/http.service';
import { Site } from 'app/models/control-center/site.model';
import { toSignal } from '@angular/core/rxjs-interop';
import { from } from 'rxjs';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
})
export class CompanyComponent implements OnInit {
  private readonly route = inject(ActivatedRoute);
  private readonly httpService = inject(HttpService);
  private readonly router = inject(Router);
  private readonly fb = inject(FormBuilder);
  private readonly dialog = inject(MatDialog);
  private readonly snackBar = inject(MatSnackBar);

  @ViewChild('siteDialog') siteDialog: any;
  @ViewChild('confirmInvalidAddressDialog') confirmInvalidAddressDialog: any;
  @ViewChild('errorDialog') errorDialog: any;
  @ViewChild('activateDialog') activateDialog: any;

  instanceAdminForm: FormGroup = this.fb.group({
    administratorPersonId: this.fb.control(null, [Validators.required]),
  });

  get administratorPersonIdControl(): AbstractControl { return this.instanceAdminForm?.get('administratorPersonId'); }
  get administratorPersonIdValue(): number { return this.administratorPersonIdControl?.value; }

  instanceColumns: any = [
    { key: 'name', label: 'Name', type: 'string' },
    { key: 'activated', label: 'Activated', type: 'boolean' },
    { key: 'lastActivityDate', label: 'Last Activity', type: 'date' },
    { key: 'people', label: 'People', type: 'string' },
  ];

  instancesTableActions = [
    { name: 'Activate', key: 'activate', requireItemSelection: 1, show: (rows: any[]) => rows?.every(r => !r.activated) },
  ];

  instancesRowActions = [
    { name: 'Activate', key: 'activate', color: 'primary', show: (row: any) => !row?.activated },
  ];

  selectedInstances: Instance[];

  orderColumns: any = [
    { key: 'id', label: 'Order ID', type: 'string' },
    { key: 'status', label: 'Order Status', type: 'string' },
    { key: 'description', label: 'Description', type: 'string' },
    { key: 'dealName', label: 'Deal', type: 'string' },
    { key: 'created', label: 'Created On', type: 'date' },
    { key: 'createdByPersonName', label: 'Created By', type: 'string' },
    { key: 'ImplmentationDate', label: 'Implmenetation Date', type: 'date' },
  ];

  sitesColumns: any = [
    { key: 'name', label: 'Name', type: 'string' },
    { key: 'instance', label: 'Instance', type: 'string' },
  ];

  saving = false;
  loading = true;
  showErrorMessage = false;

  companyId: number;
  company: Company = new Company();
  sites: Site[];
  selectedIndex: number;
  // This can take forever to load, so we've moved it outside of the ngOnInit.
  readonly people = toSignal<Person[] | null>(from(this.httpService.get(`people`)), { initialValue: null });

  instances = [];
  orders = [];
  permissions: Permissions;

  companyForm: FormGroup;
  siteDeleteError: string[] = [];

  async ngOnInit() {

    this.companyId = Number(this.route.snapshot.paramMap.get('id'));

    this.permissions = await this.httpService.get('people/authenticated/permissions');

    await this.load();
  }

  private async load() {
    this.loading = true;

    if (this.companyId !== 0) {
      await Promise.all([
        (async () => this.company = await this.httpService.get(`companies/${this.companyId}`))(),
        (async () => this.instances = await this.httpService.get(`companies/${this.companyId}/instances`))(),
        (async () => this.sites = await this.httpService.get(`companies/${this.companyId}/sites`))(),
      ]);
    }
    this.companyForm = this.buildForm();

    this.loading = false;
  }

  buildForm() {
    const formGroup = this.fb.group({
      name: this.fb.control(this.company?.name ?? this.company?.name, [Validators.required]),
      domain: this.fb.control(this.company?.domain ?? this.company?.domain, [Validators.required]),
    });

    return formGroup;
  }

  generateCompany(): void {
    this.company.name = this.companyForm.get('name').value;
    this.company.domain = this.companyForm.get('domain').value;
  }

  async save() {
    this.saving = true;
    this.showErrorMessage = false;
    this.generateCompany();
    try {
        if (this.companyId === 0) {
            const response = await this.httpService.post('companies', this.company);
            if (typeof response.id === 'number') {
                this.router.navigateByUrl(`companies/companies/${response.id}`);
                this.companyId = response.id;
                await this.load();
            }
        } else {
            await this.httpService.put('companies', this.company);
        }
    } catch {
        this.showErrorMessage = true;
    }
    this.saving = false;
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.selectedIndex = tabChangeEvent.index;
  }

  create() {
    this.router.navigateByUrl(`/companies/order/0?companyId=${this.companyId}`);
  }

  rowClickOrder(orderId) {
    this.router.navigateByUrl(`/companies/order/${orderId}?companyId=${this.companyId}`);
  }

  addSite() {
    this.router.navigateByUrl(`companies/sites/0?companyId=${this.companyId}`);
  }

  editSite(id: number) {
    this.router.navigateByUrl(`companies/sites/${id}`);
  }

  async deleteSites(ids: []) {
    this.loading = true;
    const promises = [];

    this.siteDeleteError = [];
    for (let i = 0; i < ids.length; i++) {
      promises.push(this.deleteSite(ids[i]));
    }
    await Promise.all(promises);
    if (this.siteDeleteError.length > 0) this.snackBar.open(this.siteDeleteError.join(', '), 'Dismiss', { duration: 10000 });
    await this.load();
  }

  private async deleteSite(id: number) {
    try {
        await this.httpService.delete(`companies/sites/${id}`);
    } catch (e) {
        const site = this.sites.find(s => s.id === id);
        console.error(`Failed to delete ${site.name}`, e.message);
        this.siteDeleteError.push(`Failed to delete ${site.name}: ${e.message}`);
    }
  }

  addInstance() {
    this.router.navigateByUrl(`companies/instances/0?companyId=${this.companyId}`);
  }

  editInstance(id: number) {
    this.router.navigateByUrl(`companies/instances/${id}?companyId=${this.companyId}`);
  }

  async deleteInstances(instanceIds: number[]) {
    this.loading = true;
    await this.httpService.put('companies/instances/disable', instanceIds);
    await this.load();
  }

  async handleInstancesTableAction(event: { key: string, ids: number[] }) {
    const { key, ids } = event;
    switch (key) {
      case 'activate':
        await this.handleInstancesTableRowAction({
          key: 'activate',
          rows: this.instances.filter(i => ids.includes(i.id)),
        });
        break;
    }
  }

  async handleInstancesTableRowAction(event: { key: string, rows: any[] }) {
    const { key, rows } = event;
    this.selectedInstances = rows;
    switch (key) {
      case 'activate':
        this.administratorPersonIdControl.reset();
        this.dialog.open(this.activateDialog)
          .afterClosed()
          .subscribe(async action => {
            switch (action) {
              case 'save':
                this.showErrorMessage = false;
                this.loading = true;
                try {
                      await this.httpService.post('companies/instances/activate', {
                        person: this.administratorPersonIdValue,
                        instanceIds: rows.map(r => r.id),
                      });
                      this.instances = await this.httpService.get(`companies/${this.companyId}/instances`);
                      this.loading = false;
                } catch (e) {
                      console.error('Failed to activate instance', e);
                      this.showErrorMessage = true;
                }
                break;
            }
          });
        break;
    }

  }
}
