<ng-template #modalContent>
    <div class="d-flex flex-row justify-content-between align-items-start">
        <h2 matDialogTitle>
            @if (waltId()) {
                Are you sure you want to {{actionType()}}
                <br>Wi-Fi for this device?
            } @else {
                Are you sure you want to {{actionType()}}
                <br>Wi-Fi for all devices associated
                <br>with this site?
            }
        </h2>
        <button type="button" mat-icon-button class="icon-button-small" (click)="closeDialog()">
            <mat-icon aria-hidden="false" aria-label="Close icon">close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <p *ngIf="disabled()">Wi-Fi will only be disabled for a<br>24-hour window.</p>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <div class="d-flex flex-row justify-content-between gap-3 w-100">
            <button
                mat-button
                (click)="closeDialog()"
                [disabled]="isSubmitting()"
            >Cancel</button>
            <button
                mat-raised-button
                (click)="dialogSubmit()"
                color="primary"
                [disabled]="isSubmitting()"
            >
                <div class="d-flex flex-row justify-content-center align-items-center gap-2">
                    <span>Yes</span>
                    <mat-spinner *ngIf="isSubmitting()" diameter="20"/>
                </div>
            </button>
        </div>
    </mat-dialog-actions>
</ng-template>
