import { Component, OnInit, TemplateRef, viewChild, input, output, inject, model } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Product } from '@weavix/models/src/permission/products.model';
import { Router } from '@angular/router';
import { Company } from 'app/models/control-center/company.model';
import { Instance } from 'app/models/control-center/instance.model';
import { Person } from 'app/models/control-center/person.model';
import { HttpService } from 'app/services/http.service';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { InstanceService } from 'app/services/instance.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { from } from 'rxjs';
import { sortBy } from 'lodash';

interface AddInstanceForm {
    name: FormControl<string>;
    allCompanies: FormControl<boolean>;
    isTrial: FormControl<boolean>;
    trialExpires: FormControl<Date>;

    person?: FormControl<Person>;

    products: FormGroup<{ [key: string]: FormControl<boolean> }>;
}

@Component({
    selector: 'app-instance-information',
    templateUrl: './instance-information.component.html',
    styleUrls: ['./instance-information.component.scss'],
    providers: [DatePipe],
})
export class InstanceInformationComponent implements OnInit {
    private readonly httpService = inject(HttpService);
    private readonly router = inject(Router);
    private readonly fb = inject(FormBuilder);
    private readonly dialog = inject(MatDialog);
    private readonly date = inject(DatePipe);
    private readonly instanceService = inject(InstanceService);

    private readonly activateDialog = viewChild<TemplateRef<unknown>>('activateDialog');
    private readonly productInfoDialog = viewChild<TemplateRef<unknown>>('productInfoDialog');

    readonly instance = model<Instance | null>(null);
    readonly companyId = input(0);
    readonly loading = model(false);
    readonly showErrorMessageChange = output<boolean>();

    addInstanceForm: FormGroup<AddInstanceForm>;

    readonly instanceAdminForm = this.fb.group({
        administratorPersonId: this.fb.control<Person | null>(null, [Validators.required]),
    });

    company: Company;
    products: Product[];

    // This can take forever to load, so we've moved it outside of the ngOnInit.
    readonly people = toSignal<Person[] | null>(from(this.httpService.get(`people`)), { initialValue: null });

    valueFormatters = {
        trialExpires: (value) => {
            if (!value || isNaN(value)) return value;
            try {
                return this.date.transform(new Date(+value), 'MM/dd/yyyy');
            } catch {
                return value;
            }
        },
    };

    get isTrial(): boolean {
        return this.addInstanceForm.get('isTrial').value;
    }
    get administratorPersonIdControl() {
        return this.instanceAdminForm.controls.administratorPersonId;
    }
    get administratorPersonIdValue() {
        return this.administratorPersonIdControl.value;
    }

    async ngOnInit() {
        this.loading.set(true);

        if (!this.instance()) {
            const newInstance = new Instance();
            newInstance.id = 0;
            this.instance.set(newInstance);
        }
        const companyId = this.companyId();

        await Promise.all([
            (async () => this.products = await this.getProducts())(),
            (async () => this.company = await this.httpService.get(`companies/${companyId}`))(),
        ]);

        if (this.instance().id === 0) {
            this.instance.update(prev => {
                prev.name = this.company.name;
                prev.companyId = this.company.id;
                prev.products = this.products.filter(p => p.isBaseProduct).map(p => p.key);
                prev.allCompanies = true;
                return prev;
            });
        }

        this.addInstanceForm = this.buildForm();
        this.loading.set(false);

    }

    private async getProducts(): Promise<Product[]> {
        const products = await this.httpService.get(`companies/products`) as Product[];
        // Sort by order.
        return sortBy(products, 'order').map(p => ({
            ...p,
            enabledActions: sortBy(p.enabledActions?.map(a => {
                // Sort, but keep view/edit permissions together.
                let sortKey: string = a;
                if (a?.startsWith('view-')) sortKey = '1' + a.replace(/^view-/, '');
                else if (a?.startsWith('edit-')) sortKey = '1' + a.replace(/^edit-/, '');
                return {
                    key: a,
                    sortKey,
                };
            }) ?? [], 'sortKey').map(a => a.key),
        }));
    }

    private buildForm(): FormGroup<AddInstanceForm> {
        const productsFormGroup = this.fb.group({});
        for (const product of this.products) {
            productsFormGroup.addControl(
                product.key,
                this.fb.control({
                    value: this.instance()?.products?.includes(product.key) ?? false,
                    disabled: product.isBaseProduct,
                }),
            );
        }

        const formGroup = this.fb.group<AddInstanceForm>({
            name: this.fb.control(this.instance()?.name ?? this.instance().name, [Validators.required]),
            allCompanies: this.fb.control(this.instance()?.allCompanies, []),
            isTrial: this.fb.control(!!this.instance()?.trialExpires, []),
            trialExpires: this.fb.control(
                this.instance()?.trialExpires ? new Date(+this.instance().trialExpires) : new Date(),
                this.instance()?.trialExpires ? [Validators.required] : [],
            ),
            products: productsFormGroup,
        });

        if (!this.instance().id) {
            formGroup.addControl('person', this.fb.control(null, [Validators.required]));
        }

        formGroup.get('isTrial').valueChanges
            .subscribe(value => {
                const trialExpiresControl = formGroup.get('trialExpires');
                if (value) {
                    trialExpiresControl.setValidators([Validators.required]);
                    trialExpiresControl.setValue(this.instance()?.trialExpires ? new Date(+this.instance().trialExpires) : new Date());
                } else {
                    trialExpiresControl.setValidators([]);
                    trialExpiresControl.setValue(null);
                }
                formGroup.updateValueAndValidity();
            });

        return formGroup;
    }

    private generateInstance(): void {
        this.instance.update(prev => {
            prev.name = this.addInstanceForm.get('name').value;
            prev.products = this.getSelectedProducts();
            prev.allCompanies = this.addInstanceForm.get('allCompanies').value;
            prev.trialExpires = this.addInstanceForm.get('isTrial').value ? this.addInstanceForm.get('trialExpires').value?.getTime?.() : null;
            if (prev.id === 0) prev.administratorPersonId = this.addInstanceForm?.get('person').value?.id;
            return prev;
        });
    }

    private getSelectedProducts(): string[] {
        const productKeys: string[] = [];

        for (const product of this.products) {
            if (this.addInstanceForm.controls.products.get(product.key).value) {
                productKeys.push(product.key);
            }
        }

        return productKeys;
    }

    async save() {
        this.loading.set(true);

        this.generateInstance();

        if (this.instance().id === 0) {
            await this.instanceService.create(this.instance());
        } else {
            await this.instanceService.update(this.instance());
        }

        this.loading.set(false);

        this.router.navigateByUrl(`companies/companies/${this.company.id}`);
    }

    back() {
        this.router.navigateByUrl(`companies/companies/${this.company.id}`);
    }

    activate() {
        this.administratorPersonIdControl.reset();
        this.dialog.open<unknown, unknown, string>(this.activateDialog())
            .afterClosed()
            .subscribe(async action => {
                switch (action) {
                    case 'save':
                    this.showErrorMessageChange.emit(false);
                    this.loading.set(true);
                    try {
                        await this.instanceService.activate({
                            person: this.administratorPersonIdValue,
                            instanceIds: [this.instance().id],
                        });
                        this.instance.set(await this.instanceService.getById(this.instance().id));
                        this.loading.set(false);
                    } catch (e) {
                        console.error('Failed to activate instance', e);
                        this.showErrorMessageChange.emit(true);
                    }
                    break;
                }
            });
    }

    openProductInfoDialog(product: Product) {
        this.dialog.open(this.productInfoDialog(), {
            data: product,
            minWidth: '400px',
        });
    }
}
