@if (loading()) {
    <div id="loading">
        <mat-spinner class="mt-3" diameter="20" />
    </div>
} @else {
    <div class="row scrollContainer tab">
        <div class="col-md-9 mb-9 scrollColumn tab">
            <form [formGroup]="addInstanceForm">
                <div class="row">
                    <div>
                        <p class="lead">weavix™ Instance Information</p>
                        <p class="text-muted">The instance's information.</p>
                    </div>
                    <hr />
                    <div class="col-md-6 mb-3">
                        <mat-form-field appearance="fill" style="width:100%">
                            <mat-label>Instance Name</mat-label>
                            <input matInput placeholder="Instance Name" name="name" formControlName="name">
                            @if (addInstanceForm.get('name').invalid) {
                                <mat-error>Name Required</mat-error>
                            }
                        </mat-form-field>
                    </div>

                    <div class="col-md-6 mb-3">
                        @if (instance().id === 0) {
                            @if (people(); as people) {
                                <app-autocomplete
                                    name="personId"
                                    label="Instance Administrator"
                                    [options]="people"
                                    hint="This is the first administrator that will be created"
                                    [control]="addInstanceForm.get('person')"
                                    [filterLimit]="100"
                                    displayKey="displayName"
                                />
                            } @else {
                                <div class="d-flex mt-3" style="gap: 15px;">
                                    <mat-spinner diameter="20" />
                                    Loading people...
                                </div>
                            }
                        } @else {
                            <div class="d-flex" style="padding-top: 15px; gap: 10px;">
                                @if (instance().activated) {
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                        viewBox="0 0 448 512">
                                        <path [attr.fill]="'green'"
                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" />
                                    </svg>
                                    <span class="text-success text-bold">Activated</span>
                                } @else {
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                        viewBox="0 0 320 512">
                                        <path [attr.fill]="'red'"
                                            d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
                                    </svg>
                                    <span class="text-danger text-bold">Not Activated</span>
                                }
                            </div>
                        }
                    </div>
                    <div>
                        <p class="lead">Instance Settings</p>
                        <p class="text-muted">The instance's information.</p>
                    </div>
                    <hr />
                    <div class="col-md-6 mb-3">
                        <mat-checkbox color="primary" name="allCompanies" formControlName="allCompanies">
                            Visible to Global Directory
                        </mat-checkbox>
                    </div>
                    <div class="col-md-6 mb-3">
                        <div class="row">
                            <div class="col-md-4">
                                <mat-checkbox color="primary" name="isTrial" formControlName="isTrial">
                                    Is trial?
                                </mat-checkbox>
                            </div>
                            @if (isTrial) {
                                <div class="col-md-8">
                                    <mat-form-field appearance="fill" style="width:100%">
                                        <mat-label>Trial Expires</mat-label>
                                        <input matInput name="trialExpires" formControlName="trialExpires" [matDatepicker]="trialExpires">
                                        @if (addInstanceForm.get('trialExpires').invalid) {
                                            <mat-error>
                                                @if (addInstanceForm.get('trialExpires').hasError('required')) {
                                                    Trial Expires Required
                                                } @else {
                                                    Invalid Date Format
                                                }
                                            </mat-error>
                                        }
                                        <mat-datepicker-toggle matSuffix [for]="trialExpires"></mat-datepicker-toggle>
                                        <mat-datepicker #trialExpires></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            }
                        </div>
                    </div>

                    <div>
                        <p class="lead">Instance Products</p>
                        <p class="text-muted">Products enabled for this instance.</p>
                    </div>
                    <hr />
                    <div class="col-md-6 mb-3" formGroupName="products" id="products">
                        @for (product of products; track product.key) {
                            <div
                                class="d-flex align-items-center"
                                style="gap: 7px;"
                                [matTooltip]="product.isBaseProduct ? 'Base products cannot be disabled' : null"
                                matTooltipPosition="before"
                            >
                                <mat-checkbox
                                    color="primary"
                                    [formControlName]="product.key"
                                >
                                    {{product.name}}
                                </mat-checkbox>
                                <mat-icon
                                    fontIcon="help"
                                    inline
                                    class="help-icon"
                                    (click)="openProductInfoDialog(product)"
                                />
                            </div>
                        }
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 d-flex justify-content-between">
                        <div class="d-flex mb-3" style="gap: 15px;">
                            <button
                                mat-raised-button
                                class="demo-button"
                                color="primary"
                                (click)="save()"
                                [disabled]="!this.addInstanceForm.valid"
                            >
                                Save
                            </button>
                            <button mat-raised-button class="demo-button" (click)="back()">
                                Cancel
                            </button>
                        </div>
                        @if (instance() && !instance().activated) {
                            <div class="d-flex mb-3">
                                <button mat-raised-button color="primary" class="demo-button" (click)="activate()">
                                    Activate
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </form>
        </div>
        @if (instance()) {
            <div class="col-md-3">
                <app-activity [tab]="true" [id]="instance().id" table="instance" [valueFormatters]="valueFormatters" />
            </div>
        }
    </div>
}

<ng-template #activateDialog>
    <form [formGroup]="instanceAdminForm">
        <h2 matDialogTitle>Activate Instance</h2>
        <mat-dialog-content>
            <p>Assign a user that will be the first admin of this instance.</p>
            @if (people(); as people) {
                <app-autocomplete
                    name="administratorPersonId"
                    placeHolder="Instance Administrator"
                    label="Instance Administrator"
                    [options]="people"
                    [filterLimit]="100"
                    [control]="administratorPersonIdControl"
                    displayKey="displayName">
                </app-autocomplete>
            } @else {
                <div class="d-flex mt-3" style="gap: 15px;">
                    <mat-spinner diameter="20" />
                    Loading people...
                </div>
            }
        </mat-dialog-content>
    </form>
    <mat-dialog-actions align="end">
        <button mat-raised-button matDialogClose="cancel">Cancel</button>
        <button mat-raised-button matDialogClose="save" color="primary" [disabled]="!administratorPersonIdValue">Assign</button>
    </mat-dialog-actions>

</ng-template>

<ng-template #productInfoDialog let-product>
    <h2 matDialogTitle [matTooltip]="product.key" matTooltipPosition="before">{{product.name}}</h2>
    <mat-dialog-content>
        @if (product.isBaseProduct) {
            <p class="text-muted d-flex" matTooltip="This product is a base product and cannot be disabled" matTooltipPosition="before">
                <mat-icon fontIcon="lock" inline />
                Base Product
            </p>
        }
        @if (product.description) {
            <p>{{product.description}}</p>
        }
        <h3>Permissions</h3>
        <ul class="list-unstyled">
            @for (action of product.enabledActions; track action) {
                <li>{{action}}</li>
            }
        </ul>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-button mat-dialog-close>Done</button>
    </mat-dialog-actions>
</ng-template>
