import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'app/services/http.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { uniqBy, sortBy } from 'lodash';
import { compare } from 'compare-versions';

@Component({
  selector: 'app-versions',
  templateUrl: './versions.component.html',
  styleUrls: ['./versions.component.scss'],
})
export class VersionsComponent implements OnInit {
    @ViewChild('versionDialog') versionDialog: any;

  loading = true;
  versionForm: FormGroup;
  versions: any = [];
  tableRows: any = [];
  selectedVersion: any;
  list: any;
  dialogRef: MatDialogRef<VersionsComponent>;
  displayedColumns: any = [
    { key: 'id', label: 'Name', type: 'string' },
    { key: 'mandatory', label: 'Mandatory Version', type: 'string' },
    { key: 'optional', label: 'Optional Version', type: 'string' },
    { key: 'created', label: 'Modified Date', type: 'date' },
    { key: 'modifiedBy', label: 'Modified By', type: 'string' },
  ];
  waltKey = 'walt';
  waltOtaKey = 'walt-ota-s';
  constructor(private _httpService: HttpService, private _dialog: MatDialog, private _formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.load();
  }

  async editVersion(id: number) {
    this.selectedVersion = this.versions.find(x => x.id === id);
    this.list = [{ 'key': '' }].concat(this.selectedVersion.list);

    this.versionForm = this.buildForm(this.selectedVersion);
    if (this.selectedVersion.id === this.waltKey) {
        const waltVersion = this.versions.find(x => x.id === this.waltKey);
        const waltOtaVersion = this.versions.find(x => x.id === this.waltOtaKey);
        const arrayUnion = (arr1, arr2, identifier) => {
            const array = [...arr1, ...arr2];
            return uniqBy(array, identifier);
        };
        this.list = arrayUnion(waltVersion.list, waltOtaVersion.list, 'key');
        this.list = [{ 'key': '' }].concat(this.list);
    }
    this.dialogRef = this._dialog.open(this.versionDialog);
  }

  async load() {
    this.loading = true;
    this.versions = await this._httpService.get('administration/versions');

    this.versions = sortBy(this.versions, 'id');
    const acceptedVersions = ['ios', 'android', 'walt', 'walt-ota-s', 'wilma'];
    this.versions = this.versions.filter(x => acceptedVersions.includes(x.id));
    const waltOta = this.versions.find(v => v.id === this.waltOtaKey);
    const walt = this.versions.find(v => v.id === this.waltKey);
    this.tableRows = this.versions.filter(x => x.id !== this.waltOtaKey && x.id !== this.waltKey);
    this.tableRows.push({
        id: this.waltKey,
        mandatory: `${walt?.mandatory ?? 'none'} (OTA ${waltOta?.mandatory ?? 'none'})`,
        optional: `${walt?.optional ?? 'none'} (OTA ${waltOta?.optional ?? 'none'})`,
        created: walt?.created,
        modifiedBy: walt?.modifiedBy,
    });
    this.loading = false;
  }

  async submit() {
    if (this.selectedVersion.id === this.waltKey) {
        const waltOtaVersion = this.versions.find(x => x.id === this.waltOtaKey);
        const mandatory = this.versionForm.value.mandatory;
        const optional = this.versionForm.value.optional;
        const strippedOtaVersionNumbers = this.getStrippedVersionNumbers(waltOtaVersion.list);
        const waltOtaForm = this.buildForm(waltOtaVersion);
        if (mandatory) {
            const foundVersion = this.getLessThanVersionNumber(mandatory, strippedOtaVersionNumbers, waltOtaVersion.list);
            if (foundVersion && waltOtaVersion.mandatory !== foundVersion.key) {
                waltOtaForm.get('mandatory').setValue(foundVersion.key);
            }
        } else {
            waltOtaForm.get('mandatory').setValue('');
        }
        if (optional) {
            const foundVersion = this.getLessThanVersionNumber(optional, strippedOtaVersionNumbers, waltOtaVersion.list);
            if (foundVersion && waltOtaVersion.optional !== foundVersion.key) {
                waltOtaForm.get('optional').setValue(foundVersion.key);
            }
        } else {
            waltOtaForm.get('optional').setValue('');
        }
        await this._httpService.put('administration/versions', waltOtaForm.value);
    }
    await this._httpService.put('administration/versions', this.versionForm.value);
    this.load();
    this.dialogRef.close();
  }

  buildForm(data: any) {
    const formGroup = this._formBuilder.group({
        id: new FormControl(data.id),
        type: new FormControl(data.type),
        prefix: new FormControl(data.prefix),
        mandatory: new FormControl(data.mandatory),
        optional: new FormControl(data.optional),
    });

    return formGroup;
  }

  getLessThanVersionNumber(value: string, strippedOtaVersionNumbers: string[], versions: any) {
    const lessThanVersions = this.getVersionsLessThanEqualTo(strippedOtaVersionNumbers, value.match(/[0-9]+/g).join('.'));
    const foundVersion = versions.find(x => { return x.key.includes(lessThanVersions[0]); });
    return foundVersion;
  }

  getStrippedVersionNumbers(arr) {
    arr = arr.filter(x => { return x.key !== ''; });
    return arr.map(function (item) {
        const parts = item.key.match(/[0-9]+/g);
        return parts.join('.');
    });
  }

  getVersionsLessThanEqualTo(arr, n) {
    return arr.filter(i => compare(i, n, '<='));
  }
}
