<div class="container">
    <div class="row">
        <div style="position:relative;">
            <div>
                <p class="lead">Activity</p>
                <p class="text-muted">Changes and comments</p>
            </div>
        </div>
        <hr />
        <div>
            <div class="activityContainer">
                <div #activity class="row activityColumn" [ngClass]="tab ? 'tab' : ''">
                    <div *ngFor="let event of events">
                        <div>
                            <span style="font-size:10px; padding-left:5px;">{{event.name ?
                                event.name : event.source}} | <span
                                    class="text-muted">{{utils.formatDate(event.date)}}</span></span>
                        </div>
                        <div
                            style="background-color: rgba(0, 0, 0, 0.04); border-radius: 4px 4px; padding: .75em; width: 100%; font-size:12px;">
                            <b>{{event.event}}</b><br />
                            <a *ngIf="event.properties && event.source.includes('weavix') && event.source !== 'weavixSync'"
                                style="color: #0d6efd; text-decoration: underline; cursor: pointer;"
                                (click)="openEventDialog(event)">
                                {{event.properties.length}} properties
                            </a>
                            <div [innerHtml]="event.notes"></div>
                        </div>
                    </div>
                    <div *ngIf="loading || posting">loading</div>
                </div>
            </div>
        </div>

        <div class="post" [ngClass]="tab ? 'tab' : ''" *ngIf="showAddButton">
            <hr />
            <div class="row">
                <mat-form-field style="width:100%" appearance="fill">
                    <mat-label>Comment</mat-label>
                    <textarea matInput placeholder="Comment" name="comment" [(ngModel)]="comment"
                        style="height:100px;"></textarea>
                </mat-form-field>
            </div>
            <div class="row">
                <div class="col-md-2 mb-3">
                    <button mat-raised-button class="demo-button" color="primary" (click)="addComment()"
                        [disabled]="posting || comment === ''">
                        Post
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #eventDialog>
    <h2 mat-dialog-title>{{event.event}} | {{utils.formatDate(event.date)}}</h2>
    <mat-dialog-content class="mat-typography">
        <app-table [dataSource]="event.properties" [displayedColumns]="displayedColumns" [showAdd]="false"
            [paginate]="false" uniqueKey="activity-properties"></app-table>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Done</button>
    </mat-dialog-actions>
</ng-template>