import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'app/services/http.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-bundle-type',
  templateUrl: './bundleType.component.html',
  styleUrls: ['./bundleType.component.scss'],
})
export class BundleTypeComponent implements OnInit {

  @ViewChild('assetDialog') assetDialog: any;
  @ViewChild('consumableDialog') consumableDialog: any;

  saving = false;
  loading = true;
  bundleTypeId: number;
  bundleType: any;

  bundleTypeConsumables: any = [];
  bundleTypeAssetTypes: any = [];
  consumables: any[];
  assetTypes: any[];
  assetTypeModels: any[];

  assetTypeDisabled = false;
  assetModelDisabled = false;
  consumableTypeDisabled = false;

  bundleTypeConsumableModel;
  bundleTypeAssetTypeModel;

  displayedAssetTypeColumns: any = [
    { key: 'assetTypeName', label: 'Name', type: 'string' },
    { key: 'assetModelName', label: 'Model', type: 'string' },
    { key: 'quantity', label: 'Quantity', type: 'string' },
    { key: 'menu', label: 'Actions', type: 'menu' },
  ];

  displayedConsumableColumns: any = [
    { key: 'consumableName', label: 'Name', type: 'string' },
    { key: 'quantity', label: 'Quantity', type: 'string' },
    { key: 'menu', label: 'Actions', type: 'menu' },
  ];

  assetTypeFormController = new FormControl<any>('', [Validators.required]);
  assetModelFormController = new FormControl<any>('', [Validators.required]);
  assetFormController = new FormControl('', [Validators.required]);
  consumableFormController = new FormControl<any>('', [Validators.required]);

  permissions;


  constructor(private _rotue: ActivatedRoute, private _httpService: HttpService, private _router: Router, private _dialog: MatDialog) { }

  async ngOnInit() {
    this.bundleTypeId = Number(this._rotue.snapshot.paramMap.get('id'));
    this.resetBundleTypeConsumableModel();
    this.resetBundleTypeAssetTypeModel();
    this.load();
  }

  async load() {
    this.loading = true;

    const promises = [];

    promises.push(this._httpService.get('inventory/bundleTypes/' + this.bundleTypeId + '/consumables'));
    promises.push(this._httpService.get('inventory/bundleTypes/' + this.bundleTypeId + '/assetTypes'));
    promises.push(this._httpService.get('inventory/consumables'));
    promises.push(this._httpService.get('inventory/assetTypes'));
    promises.push(this._httpService.get('people/authenticated/permissions'));

    const results = await Promise.all(promises);

    this.bundleTypeConsumables = results[0];
    this.bundleTypeAssetTypes = results[1];
    this.consumables = results[2];
    this.assetTypes = results[3];
    this.permissions = results[4];

    if (this.bundleTypeId === 0) {

      this.bundleType = {
        name: '',
      };
    } else {

      this.bundleType = await this._httpService.get('inventory/bundleTypes/' + this.bundleTypeId);
    }

    this.loading = false;
  }

  async save() {
    this.saving = true;
    if (this.bundleTypeId === 0) await this._httpService.post('inventory/bundleTypes', this.bundleType);
    else await this._httpService.put('inventory/bundleTypes', this.bundleType);
    this.saving = false;
    this._router.navigateByUrl('/inventory/bundleTypes');
  }

  resetBundleTypeConsumableModel() {
    this.bundleTypeConsumableModel = {
      consumableId: 0,
      bundleTypeId: this.bundleTypeId,
      quantity: 0,
    };
    this.consumableFormController.setValue(null);
    this.consumableFormController.markAsUntouched();
  }

  async addBundleTypeConsumable() {
    this.consumableTypeDisabled = false;
    const dialogRef = this._dialog.open(this.consumableDialog, { autoFocus: false });
    dialogRef.afterClosed().toPromise().then(async result => {
      if (result !== undefined) {
        if (result === 'save') {
          this.loading = true;
          await this._httpService.post('inventory/bundleTypes/consumables/', this.bundleTypeConsumableModel);
          this.resetBundleTypeConsumableModel();
          this.load();
        } else if (result === 'cancel') {
          this.resetBundleTypeConsumableModel();
        }
      }
    });
  }

  async editBundleTypeConsumable(id: number) {
    this.consumableTypeDisabled = true;

    this.bundleTypeConsumableModel = await this._httpService.get('inventory/bundleTypes/consumables/' + id);

    const dialogRef = this._dialog.open(this.consumableDialog, { autoFocus: false });

    dialogRef.afterClosed().toPromise().then(async result => {
      if (result !== undefined) {
        if (result === 'save') {
          this.loading = true;
          await this._httpService.put('inventory/bundleTypes/consumables', this.bundleTypeConsumableModel);
          this.resetBundleTypeConsumableModel();
          await this.load();
        } else if (result === 'cancel') {
          this.resetBundleTypeConsumableModel();
        }
      }
    });
  }

  async deleteBundleTypeConsumable(id: number) {
    this.loading = true;
    await this._httpService.delete('inventory/bundleTypes/consumables/' + id);
    this.load();
  }

  resetBundleTypeAssetTypeModel() {
    this.bundleTypeAssetTypeModel = {
      assetTypeId: 0,
      bundleTypeId: this.bundleTypeId,
      assetTypeModelId: 0,
      quantity: 0,
    };
    this.assetTypeModels = [];
    this.assetModelFormController.setValue('');
    this.assetModelFormController.markAsUntouched();
    this.assetTypeFormController.setValue('');
    this.assetTypeFormController.markAsUntouched();
  }

  async addBundleTypeAssetType() {
    this.assetTypeDisabled = false;
    this.assetModelDisabled = false;
    this.resetBundleTypeAssetTypeModel();
    const dialogRef = this._dialog.open(this.assetDialog, { autoFocus: false });
    dialogRef.afterClosed().toPromise().then(async result => {
      if (result !== undefined) {
        if (result === 'save') {
          this.loading = true;
          await this._httpService.post('inventory/bundleTypes/assetTypes/', this.bundleTypeAssetTypeModel);
          this.resetBundleTypeAssetTypeModel();
          this.load();
        } else if (result === 'cancel') {
          this.resetBundleTypeAssetTypeModel();
        }
      }
    });
  }

  async editBundleTypeAssetType(id: number) {
    this.assetTypeDisabled = true;
    this.assetModelDisabled = true;

    this.bundleTypeAssetTypeModel = await this._httpService.get('inventory/bundleTypes/assetTypes/' + id);
    this.assetTypeModels = await this._httpService.get('inventory/assetModels/' + this.bundleTypeAssetTypeModel.assetTypeModelId);

    const dialogRef = this._dialog.open(this.assetDialog, { autoFocus: false });

    dialogRef.afterClosed().toPromise().then(async result => {
      if (result !== undefined) {
        if (result === 'save') {
          this.loading = true;
          await this._httpService.put('inventory/bundleTypes/assetTypes', this.bundleTypeAssetTypeModel);
          this.resetBundleTypeAssetTypeModel();
          await this.load();
        } else if (result === 'cancel') {
          this.resetBundleTypeAssetTypeModel();
        }
      }
    });
  }

  async deleteBundleTypeAssetType(id: number) {
    this.loading = true;
    await this._httpService.delete('inventory/bundleTypes/assetTypes/' + id);
    this.load();
  }

  openConsumable(id) {
    const bundleConsumable = this.bundleTypeConsumables.find(c => c.id === id);
    this._router.navigateByUrl('/inventory/consumables/' + bundleConsumable.consumableId);
  }

  async onConsumableSelect(event) {
    if (event.id > 0) {
      this.consumableFormController.setErrors(null);
    }
    this.bundleTypeConsumableModel.consumableId = event.id;
  }

  async onConsumableBlur() {
    if (!this.consumableFormController.value.id) {
      this.consumableFormController.setErrors({ 'incorrect': true });
      await this.onConsumableSelect({ id: 0 });
    }
  }

  openAssetType(id) {
    const bundleTypeAssetType = this.bundleTypeAssetTypes.find(a => a.id === id);
    this._router.navigateByUrl('/inventory/assetTypes/' + bundleTypeAssetType.assetTypeId);
  }

  async onAssetTypeSelect(event) {
    if (event.id > 0) {
      this.assetTypeFormController.setErrors(null);
    }
    this.bundleTypeAssetTypeModel.assetTypeId = event.id;
    this.bundleTypeAssetTypeModel.assetModelId = 0;
    this.assetModelFormController.setValue('');
    this.bundleTypeAssetTypeModel.quantity = 0;
  }

  async onAssetTypeBlur() {
    if (!this.assetTypeFormController.value.id) {
      this.assetTypeFormController.setErrors({ 'incorrect': true });
      await this.onAssetTypeSelect({ id: 0 });
    }
  }

  async onAssetTypeModelSelect(event) {
    if (event.id > 0) {
      this.assetModelFormController.setErrors(null);
    }
    this.bundleTypeAssetTypeModel.assetTypeModelId = event.id;
    this.bundleTypeAssetTypeModel.quantity = 0;
  }

  async onAssetTypeModelBlur() {
    if (!this.assetModelFormController.value.id) {
      this.assetModelFormController.setErrors({ 'incorrect': true });
      await this.onAssetTypeModelSelect({ id: 0 });
    }
  }

}
