import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'app/services/http.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
})
export class OrderComponent implements OnInit {

  @ViewChild('siteSpecificationDialog') siteSpecificationDialog: any;
  @ViewChild('errorDialog') errorDialog: any;

  loading = false;

  orderId: number;
  order: any;
  products: any;
  orderProducts: any;
  companyId;
  companyName;
  catalog;

  deals: any;
  dealCurrent = { id: 0, name: '' };

  contacts: any;

  sites: any;
  siteSpecificationNew = false;
  siteSpecificationEditor = {
    'id': 0,
    'orderId': 0,
    'companyId': '',
    'companyName': '',
    'siteName': '',
    'siteId': '',
    'siteAddress': '',
    'contactName': '',
    'contactId': '',
    'contactEmail': '',
    'contactPhone': '',
    'catalog': [],
  };

  siteSpecificationsColumns = [
    { key: 'id', label: 'id', type: 'string' },
    { key: 'siteName', label: 'Site Name', type: 'string' },
    { key: 'contactName', label: 'Contact Name', type: 'string' },
    { key: 'contactEmail', label: 'Contact Email', type: 'string' },
    { key: 'contactPhone', label: 'Contact Phone', type: 'string' },
  ];
  permissions;
  siteSpecifications = [];

  errorMessage = '';

  constructor(private _rotue: ActivatedRoute, private _httpService: HttpService, private _router: Router, private _dialog: MatDialog) { }

  async ngOnInit() {

    this.loading = true;

    this.orderId = Number(this._rotue.snapshot.paramMap.get('id'));
    if (this._rotue.snapshot.queryParams['companyId']) {
      this.companyId = this._rotue.snapshot.queryParamMap.get('companyId');
    } else {
      this.companyId = 0;
    }

    const promises = new Array<any>();
    promises.push(this._httpService.get(`companies/${this.companyId}/deals`));
    promises.push(this._httpService.get('companies/products'));
    promises.push(this._httpService.get(`companies/orders/${this.orderId}`));
    promises.push(this._httpService.get(`companies/orders/${this.orderId}/products`));
    promises.push(this._httpService.get(`companies/${this.companyId}`));
    promises.push(this._httpService.get(`companies/${this.companyId}/sites`));
    promises.push(this._httpService.get(`companies/orders/${this.orderId}/site-specifications`));
    promises.push(this._httpService.get('inventory/catalog'));
    promises.push(this._httpService.get(`companies/${this.companyId}/people`));
    promises.push(this._httpService.get('people/authenticated/permissions'));
    const results = await Promise.all(promises);

    this.deals = results[0];
    this.products = results[1];
    this.order = results[2];
    if (this.order.length > 0) {
      this.order = this.order[0];
    } else {
      this.order = {};
    }
    this.orderProducts = results[3];
    this.companyName = '';
    if (results[4]) {
      this.companyName = results[4].name;
    }
    this.sites = results[5];
    this.siteSpecifications = results[6];
    this.catalog = results[7];
    this.contacts = results[8];
    this.permissions = results[9];

    this.order.products = this.orderProducts;

    if (this.orderId === 0) {

      if (this.companyName === '') {
        this._router.navigateByUrl('/companies/companies');
      }

      this.order.status = 'Draft';
      this.order.companyName = this.companyName;
      this.order.companyId = this.companyId;
      this.order.created = new Date();
      this.order.implementationDate = new Date();
      this.order.deliveryDate = new Date();
      this.order.returnDate = null;
      this.order.dealId = 0;

    } else {
      if (this.order.dealId) {
        this.dealCurrent = await this._httpService.get(`companies/deals/${this.order.dealId}`);
      }
    }
    this.loading = false;
  }

  async refreshSiteSpecificationEditor() {
    this.siteSpecificationEditor = {
      'id': 0,
      'orderId': this.orderId,
      'companyId': this.companyId,
      'companyName': this.companyName,
      'siteName': '',
      'siteAddress': '',
      'siteId': '',
      'contactName': '',
      'contactId': '',
      'contactEmail': '',
      'contactPhone': '',
      'catalog': [],
    };
    for (const x in this.catalog) {
      const pushing = {
        'type': this.catalog[x]['type'],
        'name': this.catalog[x]['name'],
        'id': this.catalog[x]['id'],
        'count': 0,
      };
      this.siteSpecificationEditor['catalog'].push(pushing);
    }
  }

  async onDealSelect(event: any) {
    this.order.dealId = event.id;
    this.order.dealName = event.name;
  }

  async onSiteSelect(event: any) {
    this.siteSpecificationEditor.siteId = event.id;
    this.siteSpecificationEditor.siteName = event.name;
    this.siteSpecificationEditor.siteAddress = event.address;
  }

  async onContactSelect(event: any) {
    this.siteSpecificationEditor.contactId = event.id;
    this.siteSpecificationEditor.contactName = event.name;
    this.siteSpecificationEditor.contactEmail = event.email;
    this.siteSpecificationEditor.contactPhone = event.phone;
  }

  async addSiteSpecification() {
    this.siteSpecificationNew = true;
    this.refreshSiteSpecificationEditor();

    const dialogRef = this._dialog.open(this.siteSpecificationDialog, { height: '70%', width: '70%', autoFocus: false });
    dialogRef.afterClosed().toPromise().then(async result => {
      if (result !== undefined) {
        if (result === 'save') {
          this.loading = true;
          if (this.siteSpecificationEditor.id === 0) {
            try {
              await this._httpService.post(`companies/orders/${this.orderId}/site-specifications`, this.siteSpecificationEditor);
            } catch (error) {
              if (error.error.includes('Duplicate entry')) {
                this.openErrorDialog(`Oops! There is already a siteSpecification for ${this.siteSpecificationEditor.siteName}.\nUpdate existing site.`);
              } else {
                this.openErrorDialog(`Oops! Something went wrong\n${error.error}}`);
              }
            }
          }
          this.siteSpecifications = await this._httpService.get(`companies/orders/${this.orderId}/site-specifications`);
          this.loading = false;
        }
      }
    });
  }

  async onSiteRowClick(event: any) {
    this.siteSpecificationEditor = await this._httpService.get(`companies/orders/site-specifications/${event}`);
    this.siteSpecificationEditor = this.siteSpecificationEditor[0];
    const dialogRef = this._dialog.open(this.siteSpecificationDialog, { height: '70%', width: '70%', autoFocus: false });
    dialogRef.afterClosed().toPromise().then(async result => {
      if (result !== undefined) {
        if (result === 'save') {
          this.loading = true;
          try {
            await this._httpService.put(`companies/orders/${this.order.id}/site-specifications/${this.siteSpecificationEditor.id}`, this.siteSpecificationEditor);
          } catch (error) {
            if (error.error.includes('Duplicate entry')) {
              this.openErrorDialog(`Oops! There is already a siteSpecification for ${this.siteSpecificationEditor.siteName}.\nUpdate existing site.`);
            } else {
              this.openErrorDialog(`Oops! Something went wrong\n${error.error}}`);
            }
          }
          this.loading = false;
          this.ngOnInit();
        } else if (result === 'cancel') {
          // this.warehouseName = ''
        }
      }
    });
  }

  async save(reRoute: boolean) {
    if (!this.order.isProject) {
      this.order.returnDate = null;
    }
    this.loading = true;
    let resp = {};
    if (this.orderId === 0) {
      resp = await this._httpService.post('companies/orders', this.order);
    } else {
      await this._httpService.put('companies/orders', this.order);
    }
    if (reRoute) {
      this._router.navigateByUrl(`/companies/companies/${this.order.companyId}`);
    } else {
      if (this.orderId === 0) {
        this._router.navigateByUrl(`/companies/order/${resp['insertId']}?companyId=${this.order.companyId}`);
        this.orderId = resp['insertId'];
      } else {
        this._router.navigateByUrl(`/companies/order/${this.orderId}?companyId=${this.order.companyId}`);
        this.ngOnInit();
      }
    }
    this.loading = false;
    return;
  }

  async cancel() {
    this._router.navigateByUrl(`/companies/companies/${this.order.companyId}`);
  }

  async openErrorDialog(errorMessage) {
    this.errorMessage = errorMessage;
    this._dialog.open(this.errorDialog);
  }

  async submit() {
    this.loading = true;
    this.order.status = 'Submitted';
    this.save(false);
    this.loading = false;
  }

  async moveToDraft() {
    this.loading = true;
    this.order.status = 'Draft';
    this.save(false);
    this.loading = false;
  }
}
