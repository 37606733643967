<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-3">
    <h1 class="h2">Warehouses</h1>
</div>

<div *ngIf="loading">loading</div>

<div *ngIf="!loading">

    <app-table [dataSource]="warehouses" [displayedColumns]="displayedColumns" (createClick)="addWarehouseModel()"
        (rowClick)="editWarehouseModel($event)" (deleteClick)="deleteWarehouseModel($event)" [permission]="permissions.Warehouses" uniqueKey="warehouses"></app-table>

</div>

<ng-template #warehouseDialog>
    <form [formGroup]="warehouseForm" class="warehouse-form">
        <h2 matDialogTitle>Site:</h2>
        <mat-dialog-content>
            <div class="row">
                <div class="col-md-6 mb-3">
                    <mat-form-field appearance="fill" style="width:100%">
                        <mat-label>Name</mat-label>
                        <input matInput placeholder="Name" name="warehouseName" formControlName="name">
                    </mat-form-field>
                </div>
                <div class="col-md-6 mb-3">
                    <mat-form-field appearance="fill" style="width:100%">
                        <mat-label>Street Address</mat-label>
                        <input matInput placeholder="Address" name="streetAddress"formControlName="streetAddress">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 mb-3">
                    <mat-form-field appearance="fill" style="width:100%">
                        <mat-label>City</mat-label>
                        <input matInput placeholder="Address" name="streetAddress" formControlName="city">
                    </mat-form-field>
                </div>
                <div class="col-md-6 mb-3">
                    <mat-form-field appearance="fill" style="width:100%">
                        <mat-label>State/Providence Code</mat-label>
                        <input matInput placeholder="Address" name="streetAddress" formControlName="stateOrProvince">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 mb-3">
                    <mat-form-field appearance="fill" style="width:100%">
                        <mat-label>Postal Code</mat-label>
                        <input matInput placeholder="Address" name="streetAddress" formControlName="postalCode">
                    </mat-form-field>
                </div>
                <div class="col-md-6 mb-3">
                    <mat-form-field appearance="fill" style="width:100%">
                        <mat-label>Country Code</mat-label>
                        <input matInput placeholder="Address" name="streetAddress" formControlName="countryCode">
                    </mat-form-field>
                </div>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button matDialogClose="cancel">Cancel</button>
            <button mat-raised-button  (click)="dialogCloseAction()" color="primary" [disabled]="!warehouseForm.valid">Save</button>
        </mat-dialog-actions>
    </form>
</ng-template>
