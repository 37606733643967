@if (loadingInstance()) {
    <div>loading</div>
} @else {
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-3">
        <h1 class="h2">
            weavix™ Instance:
            @if (instance(); as instance) {
                {{instance.name}}
            }
        </h1>
    </div>

    @if (loadingTab()) {
        <div>loading</div>
    }
    @if (errorMessage()) {
        <div class="alert alert-danger hovering-alert">
            <strong>Error occurred.</strong>
            {{errorMessage()}}
        </div>
    }

    <mat-tab-group animationDuration="0ms" [(selectedIndex)]="selectedIndex" [mat-stretch-tabs]="false">
        <mat-tab label="Information">
            <app-instance-information
                [instance]="instance()"
                [companyId]="companyId()"
                [(loading)]="loadingTab"
                (showErrorMessageChange)="handleError($event)"
            />
        </mat-tab>
        @if (isExistingInstance()) {
            <mat-tab
                [disabled]="!hasSupportAccess()"
                label="Temporary Support Access"
            >
                @if (hasSupportAccess()) {
                    <app-instance-support-access
                        [instance]="instance()"
                        [permission]="permissions()['SupportAccess']"
                        (failedLoad)="handleError($event)"
                    />
                }
            </mat-tab>
            <mat-tab
                [disabled]="!hasSupportAccess()"
                label="Records"
            >
                @if (hasSupportAccess()) {
                    <app-instance-records
                    [instance]="instance()"
                        [permission]="permissions()['SupportAccess']"
                        (failedLoad)="handleError($event)"
                    />
                }
            </mat-tab>

            <mat-tab
                [disabled]="!hasTwoWaltDemoEdit()"
                label="2 Walt Demo"
            >
                @if (hasTwoWaltDemoEdit()) {
                    <app-instance-two-walt-demo
                        [instance]="instance()"
                        [permission]="permissions()['TwoWaltDemo']"
                        (failedLoad)="handleError($event)"
                    />
                }
            </mat-tab>
            <mat-tab
                label="Feature Flags"
                [disabled]="!hasFeatureFlags()"
            >
                @if (hasFeatureFlags()) {
                    <app-instance-feature-flags [instance]="instance()" />
                }
            </mat-tab>
        }

    </mat-tab-group>
}
