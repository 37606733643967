<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-3">
    <h1 class="h2">Asset Type: <span *ngIf="assetType">{{assetType.name}}</span></h1>
</div>

<div *ngIf="loading">loading</div>

<div *ngIf="!loading">
    <div class="row">
        <div class="col-md-12">
            <form class="needs-validation" novalidate="">
                <div class="row">
                    <div class="col-md-6 mb-3">
                        <mat-form-field appearance="fill" style="width:100%">
                            <mat-label>Name</mat-label>
                            <input matInput placeholder="Serial Number" name="serialNumber"
                                [(ngModel)]="assetType.name">
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 mb-3">
                        <mat-checkbox name="isCatalog"
                                    [(ngModel)]="assetType.catalog"
                                    color="primary"
                                    disabled>
                            Catalog Item
                        </mat-checkbox>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-md-12">
                        <h3>Asset Models</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <app-table [dataSource]="assetModels" [displayedColumns]="displayedColumns"
                            (createClick)="addAssetModel()" (rowClick)="editAssetModel($event)"
                            (deleteClick)="deleteAssetModel($event)" *ngIf="this.assetTypeId !== 0" [permission]="permissions.AssetTypes" uniqueKey="asset-type"></app-table>
                        <p *ngIf="this.assetTypeId === 0">Please save the new Asset Type frist to create Asset Models</p>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-md-6 mb-3">
                        <button mat-raised-button class="demo-button" color="primary" (click)="save()">
                            Save
                        </button>
                        &nbsp;
                        <button mat-raised-button class="demo-button" [routerLink]="'/inventory/assetTypes'">
                            Cancel
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<ng-template #assetModelDialog>
    <h2 matDialogTitle>Asset Model</h2>
    <mat-dialog-content>
        <mat-form-field appearance="fill" style="width:100%">
            <mat-label>Name</mat-label>
            <input matInput placeholder="Name" name="assetModelName" [(ngModel)]="assetModel.name">
        </mat-form-field>
        <mat-form-field appearance="fill" style="width:100%">
            <mat-label>Description</mat-label>
            <textarea matInput placeholder="Comment" name="serialNumber" [(ngModel)]="assetModel.description"></textarea>
        </mat-form-field>
        <div class="col-md-6 mb-3">
            <mat-checkbox name="isCatalog"
                        [(ngModel)]="assetModel.catalog"
                        color="primary">
                Catalog Item
            </mat-checkbox>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-button matDialogClose="cancel">Cancel</button>
        <button mat-button matDialogClose="save">Save</button>
    </mat-dialog-actions>
</ng-template>
