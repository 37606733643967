export class Instance {
    id?: number;
    companyId: number;
    name: string;
    /** A collection of product keys. */
    products?: string[];
    weavixAccountId?: string;
    disabled: boolean;
    administratorPersonId?: number;
    displayName?: string;
    allCompanies?: boolean;
    /** Unix ms. */
    trialExpires?: number;
    activated?: boolean;
}
