
export function isVersionNewer(current?: string, version?: string) {
    const matchParts = version?.match(/\d+/g);
    const foundParts = current?.match(/\d+/g);

    if (!matchParts) return false;
    if (!foundParts) return true;

    for (let i = 0; i < matchParts?.length; i++) {
        if (!foundParts || foundParts.length <= i || Number(matchParts[i]) > Number(foundParts[i])) return true;
        if (Number(matchParts[i]) < Number(foundParts[i])) break;
    }
    return false;
}
