import { Component, computed, input, output, signal } from '@angular/core';
import { Instance } from 'app/models/control-center/instance.model';
import { Person } from 'app/models/control-center/person.model';

@Component({
    selector: 'app-delete-person-modal',
    templateUrl: './delete-person-modal.component.html',
    styleUrls: ['./delete-person-modal.component.scss'],
})
export class DeletePersonModalComponent {
    readonly person = input<Person>(null);
    readonly instances = input<Instance[]>([]);

    readonly closeEvent = output();
    readonly submitEvent = output();

    readonly deleteText = signal('');

    readonly instanceList = computed(() => {
        return this.instances().map(instance => instance.name).join(', ');
    });

    onCancel() {
        this.deleteText.set('');
        this.closeEvent.emit();
    }

    onSubmit() {
        this.deleteText.set('');
        this.submitEvent.emit();
    }
}
