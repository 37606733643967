<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
    <h1 class="h2">Versions</h1>
</div>

<div *ngIf="loading">loading</div>

<app-table [dataSource]="tableRows" [displayedColumns]="displayedColumns" [route]="'route'" (rowClick)="editVersion($event)"
            [checkBoxes]="false" *ngIf="!loading" [showAdd]="false" uniqueKey="versions" ></app-table>

<ng-template #versionDialog>
    <form class="version-form" [formGroup]="versionForm" >
        <h2 matDialogTitle>{{selectedVersion.id}}:</h2>
        <mat-dialog-content>
            <p *ngIf="selectedVersion.id === waltKey">This will automatically set the walt-ota-s version <= the walt version selected.</p>
            <div class="row">
                <div class="col-md-12 mb-3">
                    <mat-form-field appearance="fill" style="width:100%">
                        <mat-label>Mandatory</mat-label>
                        <mat-select 
                            name="mandatory-version"  formControlName="mandatory">
                            <mat-option *ngFor="let item of list" [value]="item.key">
                                {{item.key}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" style="width:100%">
                        <mat-label>Optional</mat-label>
                        <mat-select 
                            name="optional-version" formControlName="optional">
                            <mat-option *ngFor="let item of list" [value]="item.key">
                                {{item.key}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button matDialogClose="cancel">Cancel</button>
            <button mat-raised-button (click)="submit()" color="primary">Submit</button>
        </mat-dialog-actions>
    </form>
</ng-template>
