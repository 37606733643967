import { Component, computed, OnChanges, SimpleChanges, input, TemplateRef, signal, viewChild, output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpService } from 'app/services/http.service';

@Component({
    selector: 'app-enable-disable-walt-wifis-modal',
    templateUrl: './enable-disable-walt-wifis-modal.component.html',
    styleUrls: ['./enable-disable-walt-wifis-modal.component.scss'],
})
export class EnableDisableWaltWifisModalComponent implements OnChanges {
    readonly open = input.required<boolean>();
    readonly instanceId = input<number>();
    readonly siteId = input<number>();
    readonly waltId = input<number>();
    readonly disabled = input.required<boolean>();

    readonly modalContent = viewChild<TemplateRef<unknown>>('modalContent');

    readonly loadingChanged = output<boolean>();
    readonly submitEvent = output();
    readonly closeEvent = output();
    readonly errorEvent = output<any>();

    readonly isSubmitting = signal(false);
    private dialogRef: MatDialogRef<unknown>;

    constructor(
        private _dialog: MatDialog,
        private _snackBar: MatSnackBar,
        private _httpService: HttpService,
    ) { }

    readonly actionType = computed(() => this.disabled() ? 'temporarily disable' : 'enable');

    ngOnChanges(changes: SimpleChanges): void {
        if ('open' in changes) {
            if (this.open()) {
                this.openDialog();
            } else {
                this.closeDialog();
            }
        }
    }

    private openDialog() {
        this.dialogRef = this._dialog.open(this.modalContent(), { id: 'enable-disable-wifi' });
        this.dialogRef.backdropClick().subscribe(() => this.closeDialog());
    }

    async dialogSubmit() {
        this.loadingChanged.emit(true);
        this.isSubmitting.set(true);
        try {
            const enableDisableWaltWifiRequest = {
                instanceId: this.instanceId()?.toString(),
                siteId: this.siteId()?.toString(),
                waltId: this.waltId()?.toString(),
                disabled: this.disabled(),
            };
            await this._httpService.post(`administration/enable-disable-walt-wifis`, enableDisableWaltWifiRequest);
            this.submitEvent.emit();
        } catch (error) {
            this.errorEvent.emit(error);
            this._snackBar.open(`Failed to ${this.disabled() ? 'disable' : 'enable'} walt wifi: ${error.message}`, 'Dismiss', { duration: 10000 });
        } finally {
            this.loadingChanged.emit(false);
            this.isSubmitting.set(false);
            this.closeDialog();
        }
    }

    closeDialog() {
        this.dialogRef?.close();
        this.closeEvent.emit();
    }
}
