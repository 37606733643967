import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ActivityComponent } from './components/activity/activity.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AssetComponent } from './inventory/asset/asset.component';
import { AssetTypeComponent } from './inventory/assetType/assetType.component';
import { AssetTypesComponent } from './inventory/assetTypes/assetTypes.component';
import { AssetsComponent } from './inventory/assets/assets.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { BundleComponent } from './inventory/bundle/bundle.component';
import { BundleTypeComponent } from './inventory/bundleType/bundleType.component';
import { BundleTypesComponent } from './inventory/bundleTypes/bundleTypes.component';
import { BundlesComponent } from './inventory/bundles/bundles.component';
import { ChipComponent } from './components/chip/chip.component';
import { CommonModule } from '@angular/common';
import { CompaniesComponent } from './companies/companies/companies.component';
import { CompanyComponent } from './companies/company/company.component';
import { ConsumableComponent } from './inventory/consumable/consumable.component';
import { ConsumablesComponent } from './inventory/consumables/consumables.component';
import { HomeComponent } from './home/home.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { InstanceComponent } from './companies/instance/instance.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { OrderComponent } from './companies/order/order.component';
import { PeopleComponent } from './companies/people/people.component';
import { PersonComponent } from './companies/person/person.component';
import { SiteComponent } from './companies/site/site.component';
import { TableComponent } from './components/table/table.component';
import { TableMultiFilterComponent } from './components/tableMultiFilter/tableMultiFilter.component';
import { VersionsComponent } from './administration/versions/versions.component';
import { WarehousesComponent } from './inventory/warehouses/warehouses.component';
import { ScannerComponent } from './inventory/scanner/scanner.component';
import { OrphanedInstancesComponent } from './administration/orphanedInstances/orphanedInstances.component';
import { InstanceInformationComponent } from './companies/instance/instance-information/instance-information.component';
import { InstanceRecordsComponent } from './companies/instance/instance-records/instance-records.component';
import { InstanceSupportAccessComponent } from './companies/instance/instance-support-access/instance-support-access.component';
import { InstanceSupportAccessUserFormComponent } from './companies/instance/instance-support-access/instance-support-access-user-form/instance-support-access-user-form.component';
import { InstanceSupportAccessTableComponent } from './companies/instance/instance-support-access/instance-support-access-table/instance-support-access-table.component';

import { MatTooltipDefaultOptions, MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipModule } from '@angular/material/tooltip';
import { UnauthenticatedInterceptor } from 'app/services/unauthenticated.interceptor';
import { SessionExpiryBannerComponent } from './components/sessionExpiryBanner/sessionExpiryBanner.component';
import { RefreshAuthInterceptor } from 'app/services/refreshAuth.interceptor';
import { InstanceTwoWaltDemoComponent } from './companies/instance/instance-two-walt-demo/instance-two-walt-demo.component';
import { SendLogsModalComponent } from './components/sendLogsModal/sendLogsModal.component';
import { AddressSearchComponent } from './components/addressSearch/address-search.component';
import { EnableDisableWaltWifisModalComponent } from './components/enable-disable-walt-wifis-modal/enable-disable-walt-wifis-modal.component';
import { DeleteWaltSavedWifisModalComponent } from './components/delete-walt-saved-wifis-modal/delete-walt-saved-wifis-modal.component';
import { DeletePersonModalComponent } from './companies/person/delete-person-modal/delete-person-modal.component';
import { InstanceFeatureFlagsComponent } from './companies/instance/instance-feature-flags/instance-feature-flags.component';
import { FeaturesComponent } from './administration/features/features.component';

export const matTooltipOptions: MatTooltipDefaultOptions = {
    showDelay: 0,
    hideDelay: 0,
    touchGestures: 'auto',
    position: 'below',
    touchendHideDelay: 0,
    disableTooltipInteractivity: true,
};

@NgModule({
  declarations: [
    AppComponent,
    AuthenticationComponent,
    CompaniesComponent,
    CompanyComponent,
    TableComponent,
    TableMultiFilterComponent,
    AddressSearchComponent,
    AssetTypesComponent,
    AutocompleteComponent,
    AssetsComponent,
    AssetComponent,
    AssetTypeComponent,
    WarehousesComponent,
    ChipComponent,
    ConsumablesComponent,
    ConsumableComponent,
    EnableDisableWaltWifisModalComponent,
    DeleteWaltSavedWifisModalComponent,
    PeopleComponent,
    BundlesComponent,
    BundleComponent,
    ActivityComponent,
    PersonComponent,
    OrderComponent,
    InstanceComponent,
    VersionsComponent,
    HomeComponent,
    BundleTypesComponent,
    BundleTypeComponent,
    SessionExpiryBannerComponent,
    SiteComponent,
    ScannerComponent,
    SendLogsModalComponent,
    OrphanedInstancesComponent,
    InstanceInformationComponent,
    InstanceRecordsComponent,
    InstanceSupportAccessComponent,
    InstanceSupportAccessUserFormComponent,
    InstanceSupportAccessTableComponent,
    InstanceTwoWaltDemoComponent,
    DeletePersonModalComponent,
    InstanceFeatureFlagsComponent,
    FeaturesComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    GoogleMapsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    FormsModule,
    MatSortModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatTabsModule,
    MatProgressBarModule,
    MatBadgeModule,
    MatSelectModule,
    MatDialogModule,
    MatMenuModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatIconModule,
  ],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: matTooltipOptions },
    { provide: HTTP_INTERCEPTORS, useClass: UnauthenticatedInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RefreshAuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
