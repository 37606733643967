<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
    <h1 class="h2">{{this.assetType}}</h1>
</div>

<div *ngIf="loading">loading</div>

<mat-tab-group animationDuration="0ms" *ngIf="!loading" style="margin-bottom: 5px;" [mat-stretch-tabs]="false">

    <mat-tab label="List" class="pb-5 p-5">

        <app-table-multi-filter [dataSource]="dataSource" [displayedColumns]="displayedColumns" [route]="'route'" [checkBoxes]="true"
            *ngIf="!loading" (createClick)="add()" (rowClick)="rowClick($event)" (deleteClick)="delete($event)"
            [permission]="permissions.Assets" [showImport]="true" (importClick)="showImport()" [filters]="tableFilter"
            [additionalActions]="tableActions" (actionClick)="handleTableAction($event)" uniqueKey="assets-{{this.assetType}}"
            [exportRowOverride]="exportRowOverride"></app-table-multi-filter>

    </mat-tab>
    <mat-tab label="Map" class="pb-5 p-5" *ngIf="this.assetTypeId === waltAssetType">

        <div *ngIf="loading">loading</div>

        <google-map *ngIf="!loading" [height]="'100vh'" [width]="null" (idle)="fitBounds()">
            <ng-container
                *ngFor="let item of dataSource"
            >
                <map-marker
                    *ngIf="item.latitude && item.longitude"
                    #marker="mapMarker"
                    [position]="{ lat: +item.longitude, lng: +item.latitude }"
                    [title]="item.serialNumber"
                    (mapClick)="markerClick(item, marker)"
                >
                </map-marker>
            </ng-container>
            <map-info-window (closeclick)="openedAsset = null">
                <a [routerLink]="['/inventory/assets/', openedAsset?.id]">{{openedAsset?.serialNumber}}</a>
            </map-info-window>
        </google-map>

    </mat-tab>
</mat-tab-group>


<ng-template #importDialog>
    <h2 matDialogTitle>Import Assets</h2>
    <mat-dialog-content>
        <p>Import a csv. Columns with headers of any one the following will be imported.</p>
        <p>All imported assets will be placed in the "Incoming Forecast" status.</p>
        <ul>
            <li>serialNumber</li>
            <li>imeiOne</li>
            <li>eIdOne</li>
            <li>imeiTwo</li>
            <li>eIdTwo</li>
            <li>bluetoothMac</li>
            <li>wifiMac</li>
            <li>hardwireMac</li>
        </ul>
        <p>
            <input type="file" id="file" name="file" accept="text/csv" (change)="onFileSelected($event)">
        </p>
        <mat-form-field appearance="fill" style="width:100%">
            <mat-label>Model</mat-label>
            <mat-select [(value)]="modelId">
                <mat-option *ngFor="let model of models" [value]="model.id">
                    {{model.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" style="width:100%">
            <mat-label>Warehouse</mat-label>
            <mat-select [(value)]="warehouseId">
                <mat-option *ngFor="let warehouse of warehouses" [value]="warehouse.id">
                    {{warehouse.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" style="width:100%">
            <mat-label>Expected Delivery Date</mat-label>
            <input matInput name="expectedDeliveryDate" [matDatepicker]="expectedDeliveryDatePicker"
                [(ngModel)]="expectedDeliveryDate">
            <mat-datepicker-toggle matSuffix [for]="expectedDeliveryDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #expectedDeliveryDatePicker></mat-datepicker>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button matDialogClose="cancel">Cancel</button>
        <button mat-button matDialogClose="import"
            [disabled]="!warehouseId || !modelId || !expectedDeliveryDate || !file">Import</button>
    </mat-dialog-actions>
</ng-template>

<ng-template #importResultDialog>
    <h2 matDialogTitle>Import Assets</h2>
    <mat-dialog-content>
        <p>{{importResult}}</p>
    </mat-dialog-content>
    
    <mat-dialog-actions align="end">
        <button mat-button matDialogClose="cancel">Close</button>
    </mat-dialog-actions>
</ng-template>

<ng-template #updateStatusDialog>
    <h2 matDialogTitle>Update {{bulkUpdateStatuses.assetIds.length}} Status(es)</h2>
    <mat-dialog-content>
        <div *ngIf="people.length === 0" style="display:flex;justify-content:space-around;"><mat-spinner diameter="20"></mat-spinner>Loading People...</div>
        <div *ngIf="people.length !== 0">
            <p>Update the status of multiple assets in bulk.</p>
            <p>Note - Any assets that are part of a bundle of a sub asset will not have their statuses updated.</p>
            <mat-form-field appearance="fill" style="width:100%">
                <mat-label>Status</mat-label>
                <mat-select [(ngModel)]="bulkUpdateStatuses.status" name="status" ngDefaultControl>
                    <mat-option [value]="assetStatuses.Available">
                        Available | <small>Asset is ready for use.</small>
                    </mat-option>
                    <mat-option [value]="assetStatuses.CheckedOut">
                        Checked Out | <small>Asset is checked out to a specific site.</small> 
                    </mat-option>
                    <mat-option [value]="assetStatuses.Decommissioned">
                        Decommissioned | <small>Asset is no longer usable and
                            will be discarded.</small>
                    </mat-option>
                    <mat-option [value]="assetStatuses.Maintenance">
                        Maintenance | <small>Asset requires maintenance before
                            it is ready for use.</small>
                    </mat-option>
                    <mat-option [value]="assetStatuses.Provisioning">
                        Provisioning | <small>Asset requires verification before
                            it is ready for use</small>
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" style="width:100%" *ngIf="[assetStatuses.Available, assetStatuses.Provisioning, assetStatuses.Maintenance].includes(bulkUpdateStatuses.status)">
                <mat-label>Warehouse</mat-label>
                <mat-select name="warehouseId" [(ngModel)]="bulkUpdateStatuses.warehouseId">
                    <mat-option *ngFor="let warehouse of warehouses" [value]="warehouse.id">
                        {{warehouse.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field style="width:100%" appearance="fill" *ngIf="[assetStatuses.Available].includes(bulkUpdateStatuses.status)">
                <mat-label>Warehouse Location</mat-label>
                <input matInput placeholder="Warehouse Location" name="warehouseLocation"
                    [(ngModel)]="bulkUpdateStatuses.warehouseLocation">
            </mat-form-field>
            <app-autocomplete [options]="sites" name="siteId" [value]="bulkUpdateStatuses.siteId"
                label="Company Site the asset is assinged to" (optionChange)="bulkUpdateStatuses.siteId = $event.id"
                [filterLimit]="100" *ngIf="bulkUpdateStatuses.status === assetStatuses.CheckedOut">
            </app-autocomplete>
            <app-autocomplete [options]="people" name="personId" [value]="bulkUpdateStatuses.personId"
                label="Person Assigned To" (optionChange)="bulkUpdateStatuses.personId = $event.id" [filterLimit]="100"
                displayKey="displayName"
                *ngIf="[assetStatuses.CheckedOut, assetStatuses.Maintenance].includes(bulkUpdateStatuses.status)">
            </app-autocomplete>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions *ngIf="people.length !== 0" align="end">
        <button mat-button matDialogClose="cancel">Cancel</button>
        <button mat-button matDialogClose="update"
        [disabled]="bulkUpdateStatuses.status === undefined 
        || ([assetStatuses.Available, assetStatuses.Provisioning, assetStatuses.Maintenance].includes(bulkUpdateStatuses.status) && bulkUpdateStatuses.warehouseId === undefined) 
        || ([assetStatuses.Maintenance].includes(bulkUpdateStatuses.status) && bulkUpdateStatuses.personId === undefined) 
        || ([assetStatuses.CheckedOut].includes(bulkUpdateStatuses.status) && bulkUpdateStatuses.siteId === undefined)
        || ([assetStatuses.Available].includes(bulkUpdateStatuses.status) && bulkUpdateStatuses.warehouseLocation === undefined)">Update</button>
    </mat-dialog-actions>
</ng-template>
