import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpService } from 'app/services/http.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PersonComponent } from 'app/companies/person/person.component';

export enum LogsType {
    Person = 'person',
    Walt = 'walt',
}

@Component({
    selector: 'app-send-logs-modal',
    templateUrl: './sendLogsModal.component.html',
    styleUrls: ['./sendLogsModal.component.scss'],
})
export class SendLogsModalComponent implements OnInit {
    @Input() id: number;
    @Input() logsType: LogsType;
    @Output() loadingChanged = new EventEmitter<boolean>();
    @Output() errored = new EventEmitter<boolean>();
    @Output() allDone = new EventEmitter<boolean>();
    @ViewChild('pullLogsDialog') pullLogsDialog: any;

    logsForm: FormGroup;
    dialogRef: MatDialogRef<PersonComponent>;

    constructor(
        private _dialog: MatDialog,
        private _httpService: HttpService,
        private _formBuilder: FormBuilder,
    ) { }

    ngOnInit(): void {
        this.pullLogs();
    }

    buildLogsForm() {
      const formGroup = this._formBuilder.group({
        reason: new FormControl({ value: '', disabled: false }, Validators.required),
        zendesk: new FormControl({ value: true, disabled: false }, Validators.required),
        logsDate: new FormControl(new Date().toLocaleString()),
      });

      return formGroup;
    }

    pullLogs() {
      this.logsForm = this.buildLogsForm();
      setTimeout(() => {
        this.dialogRef = this._dialog.open(this.pullLogsDialog, { id: 'create' });
        this.dialogRef.backdropClick().subscribe(() => {
          this.allDone.emit(true);
        });
      });
    }

    async dialogSubmit() {
      if (this.logsForm.valid) {
        this.loadingChanged.emit(true);
        try {
          this.dialogRef.close();
          const options = {
            reason: this.logsForm.value.reason,
            zendesk: this.logsForm.value.zendesk,
            logsDate: new Date(this.logsForm.value.logsDate).toISOString(),
          };
          if (this.logsType === LogsType.Person) await this._httpService.post(`people/${this.id}/logs`, options);
          else await this._httpService.post(`inventory/assets/${this.id}/logs`, options);
        } catch (error) {
          console.error('Failed to request logs', error);
          this.errored.emit(true);
        } finally {
          this.loadingChanged.emit(false);
          this.allDone.emit(true);
        }
      }
    }

    closeDialog() {
      this.dialogRef.close();
      this.allDone.emit(true);
    }
}
