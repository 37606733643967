import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'app/services/http.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-bundle',
  templateUrl: './bundle.component.html',
  styleUrls: ['./bundle.component.scss'],
})
export class BundleComponent implements OnInit {

  @ViewChild('assetDialog') assetDialog: any;
  @ViewChild('consumableDialog') consumableDialog: any;
  @ViewChild('errorDialog') errorDialog: any;

  saving = false;
  loading = true;
  bundle: any;
  bundleId: number;

  bundleConsumables: any = [];
  bundleAssets: any = [];
  consumables: any[];
  assets: any[];
  assetTypes: any[];
  assetTypeModels: any[];
  assetModels: any[];
  warehouses: any = [];
  people: any = [];
  completeness: any;
  assignedPerson: any = { email: '', phone: '', company: '' };

  assetTypeFormController = new FormControl<any>('', [Validators.required]);
  assetModelFormController = new FormControl<any>('', [Validators.required]);
  assetFormController = new FormControl<any>('', [Validators.required]);
  warehouseIdControl = new FormControl('', [Validators.required]);
  personIdControl = new FormControl('', [Validators.required]);
  warehouseLocationControl = new FormControl('', [Validators.required]);
  serialNumberControl = new FormControl('', [Validators.required]);
  statusControl = new FormControl('', [Validators.required]);
  consumableFormController = new FormControl<any>('', [Validators.required]);

  bundleConsumableModel;
  bundleAssetModel;
  errorMessage;

  displayedConsumableColumns: any = [
    { key: 'name', label: 'Name', type: 'string' },
    { key: 'quantity', label: 'Quantity', type: 'string' },
    { key: 'menu', label: 'Actions', type: 'menu' },
  ];

  displayedAssetColumns: any = [
    { key: 'assetType', label: 'Asset Type', type: 'string' },
    { key: 'assetModel', label: 'Asset Model', type: 'string' },
    { key: 'alais', label: 'Name', type: 'string' },
    { key: 'serialNumber', label: 'Serial Number', type: 'string' },
    { key: 'parentAsset', label: 'Parent Asset', type: 'string' },
    { key: 'menu', label: 'Actions', type: 'menu' },
  ];
  permissions;


  constructor(private _rotue: ActivatedRoute, private _httpService: HttpService, private _router: Router, private _dialog: MatDialog) { }

  async ngOnInit() {
    this.bundleId = Number(this._rotue.snapshot.paramMap.get('id'));
    this.resetBundleAssetModel();
    this.resetBundleConsumableModel();
    this.load();
  }

  async load() {
    this.loading = true;

    const promises = [];

    promises.push(this._httpService.get('inventory/bundles/' + this.bundleId + '/consumables'));
    promises.push(this._httpService.get('inventory/assets?bundleId=' + this.bundleId));
    promises.push(this._httpService.get('inventory/consumables'));
    promises.push(this._httpService.get('inventory/assetTypes'));
    promises.push(this._httpService.get('inventory/assets'));
    promises.push(this._httpService.get('inventory/warehouses'));
    promises.push(this._httpService.get('people'));
    promises.push(this.bundleCompleteness());
    promises.push(this._httpService.get('people/authenticated/permissions'));

    const results = await Promise.all(promises);

    this.bundleConsumables = results[0];
    this.bundleAssets = results[1];
    this.consumables = results[2];
    this.assetTypes = results[3];
    this.assets = results[4];
    this.warehouses = results[5];
    this.people = results[6];
    this.permissions = results[8];

    if (this.bundleId === 0) {

      const bundleTypeModel = await this._httpService.get('inventory/bundleTypes/' + this._rotue.snapshot.queryParams['bundleTypeId']);

      this.bundle = {
        name: '',
        status: null,
        bundleTypeId: this._rotue.snapshot.queryParams['bundleTypeId'],
        bundleTypeName: bundleTypeModel['name'],
      };
    } else {

      this.bundle = await this._httpService.get('inventory/bundles/' + this.bundleId);
      if (this.bundle === null) {
        this._router.navigateByUrl('/inventory/bundleTypes');
      } else if (this.bundle.personId) {
        this.assignedPerson = await this._httpService.get('people/' + this.bundle.personId);
      }
    }

    this.loading = false;
  }

  async save() {
    this.loading = true;
    this.saving = true;
    if (this.bundleId === 0) await this._httpService.post('inventory/bundles', this.bundle);
    else await this._httpService.put('inventory/bundles', this.bundle);

    for (const index in this.bundleAssets) {
      if (this.bundleAssets[index].parentAsset) {
        this.addAssetandSubAsset(this.bundleAssets[index], false);
      } else {
        this.addAssetandSubAsset(this.bundleAssets[index], true);
      }
    }

    this.saving = false;
    this.loading = false;
    this._router.navigateByUrl('/inventory/bundleTypes/bundles/' + this.bundle.bundleTypeId);
    // this.load();
  }

  async bundleCompleteness() {
    let requiredAssets;
    let requiredConsumables;
    let currentBundleAssets;
    let currentBundleConsumables;

    await Promise.all([
      (async () => requiredAssets = await this._httpService.get('inventory/bundles/' + this.bundleId + '/completeness?type=requiredAssets'))(),
      (async () => requiredConsumables = await this._httpService.get('inventory/bundles/' + this.bundleId + '/completeness?type=requiredConsumables'))(),
      (async () => currentBundleAssets = await this._httpService.get('inventory/bundles/' + this.bundleId + '/completeness?type=currentAssets'))(),
      (async () => currentBundleConsumables = await this._httpService.get('inventory/bundles/' + this.bundleId + '/completeness?type=currentConsumables'))(),
    ]);

    this.completeness = {
      complete: true,
      components: {},
    };

    for (const index in requiredAssets) {
      const inserting = {
          required: requiredAssets[index]['quantity'],
          allocated: 0,
      };
      this.completeness['components'][`Asset | ${requiredAssets[index]['assetTypeName']} | ${requiredAssets[index]['assetTypeModelName']}`] = { ...inserting };
    }

    for (const index in requiredConsumables) {
        const inserting = {
            required: requiredConsumables[index]['quantity'],
            allocated: 0,
        };
        this.completeness['components'][`Consumable | ${requiredConsumables[index]['consumableName']}`] = { ...inserting };
    }

    for (const index in currentBundleAssets) {
        const keyString = `Asset | ${currentBundleAssets[index]['assetTypeName']} | ${currentBundleAssets[index]['assetTypeModelName']}`;
        if (keyString in this.completeness['components']) {
          this.completeness['components'][keyString]['allocated'] += 1;
        }
    }

    for (const index in currentBundleConsumables) {
        const keyString = `Consumable | ${currentBundleConsumables[index]['consumableName']}`;
        if (keyString in this.completeness['components']) {
          this.completeness['components'][keyString]['allocated'] += currentBundleConsumables[index]['quantity'];
        }
    }

    for (const key in this.completeness['components']) {
        if (this.completeness['components'][key]['allocated'] < this.completeness['components'][key]['required']) {
          this.completeness['complete'] = false;
        }
    }
  }

  resetBundleConsumableModel() {
    this.bundleConsumableModel = {
      consumableId: 0,
      bundleId: this.bundleId,
      quantity: 0,
    };
    this.consumableFormController.setValue(null);
    this.consumableFormController.markAsUntouched();
  }

  async onConsumableSelect(event) {
    if (event.id > 0) {
      this.consumableFormController.setErrors(null);
    }
    this.bundleConsumableModel.consumableId = event.id;
  }

  async onConsumableBlur() {
    if (!this.consumableFormController.value.id) {
      this.consumableFormController.setErrors({ 'incorrect': true });
      await this.onConsumableSelect({ id: 0 });
    }
  }

  async addBundleConsumable() {
    const dialogRef = this._dialog.open(this.consumableDialog, { autoFocus: false });
    dialogRef.afterClosed().toPromise().then(async result => {
      if (result !== undefined) {
        if (result === 'save') {
          this.loading = true;
          await this._httpService.post('inventory/bundles/consumables/', this.bundleConsumableModel);
          this.load();
        }
      }
      this.resetBundleConsumableModel();
    });
  }

  async editBundleConsumable(id: number) {

    this.bundleConsumableModel = await this._httpService.get('inventory/bundles/consumables/' + id);

    const dialogRef = this._dialog.open(this.consumableDialog, { autoFocus: false });

    dialogRef.afterClosed().toPromise().then(async result => {
      if (result !== undefined) {
        if (result === 'save') {
          this.loading = true;
          await this._httpService.put('inventory/bundles/consumables', this.bundleConsumableModel);
          await this.load();
        }
      }
      this.resetBundleConsumableModel();
    });
  }

  async deleteBundleConsumable(id: number) {
    this.loading = true;
    await this._httpService.delete('inventory/bundles/consumables/' + id);
    this.load();
  }

  async resetBundleAssetModel() {
    this.bundleAssetModel = {
      assetId: 0,
      assetModelId: 0,
      assetTypeId: 0,
      bundleId: this.bundleId,
    };
    this.assetFormController.setValue('');
    this.assetFormController.markAsUntouched();
    this.assetModelFormController.setValue('');
    this.assetModelFormController.markAsUntouched();
    this.assetTypeFormController.setValue('');
    this.assetTypeFormController.markAsUntouched();
  }

  async onAssetTypeSelect(event) {
    if (event.id > 0) {
      this.assetTypeFormController.setErrors(null);
    }
    this.bundleAssetModel.assetTypeId = event.id;
    this.bundleAssetModel.assetModelId = 0;
    this.assetModelFormController.setValue('');
    this.bundleAssetModel.assetId = 0;
    this.assetFormController.setValue('');
  }

  async onAssetTypeBlur() {
    if (!this.assetTypeFormController.value.id) {
      this.assetTypeFormController.setErrors({ 'incorrect': true });
      await this.onAssetTypeSelect({ id: 0 });
    }
  }

  async onAssetTypeModelSelect(event) {
    if (event.id > 0) {
      this.assetModelFormController.setErrors(null);
    }
    this.bundleAssetModel.assetModelId = event.id;
    this.bundleAssetModel.assetId = 0;
    this.assetFormController.setValue('');
  }

  async onAssetTypeModelBlur() {
    if (!this.assetModelFormController.value.id) {
      this.assetModelFormController.setErrors({ 'incorrect': true });
      await this.onAssetTypeModelSelect({ id: 0 });
    }
  }

  async onAssetSelect(event) {
    if (event.id > 0) {
      this.assetFormController.setErrors(null);
    }
    this.bundleAssetModel.assetId = event.id;
  }

  async onAssetBlur() {
    if (!this.assetFormController.value.id) {
      this.assetFormController.setErrors({ 'incorrect': true });
      await this.onAssetSelect({ id: 0 });
    }
  }

  async onPersonSelect(event) {
    this.bundle.personId = event.id;
    this.assignedPerson = event;
  }

  async addBundleAsset() {
    const dialogRef = this._dialog.open(this.assetDialog, { autoFocus: false });
    dialogRef.afterClosed().toPromise().then(async result => {
      if (result !== undefined) {
        if (result === 'save') {
          this.loading = true;
          await this.addAssetandSubAsset(this.assetFormController.value);
          this.load();
        }
      }
      this.resetBundleAssetModel();
    });
  }

  async addAssetandSubAsset(assetToAdd, checkSubAsset = true) {
    assetToAdd.bundleId = this.bundle.id;
    assetToAdd.status = this.bundle.status;
    assetToAdd.personId = this.bundle.personId;
    assetToAdd.warehouseId = this.bundle.warehouseId;
    assetToAdd.warehouseLocation = this.bundle.warehouseLocation;
    await this._httpService.put('inventory/assets', assetToAdd);
    if (checkSubAsset) {
      const childAssets = await this._httpService.get('inventory/assets/' + assetToAdd.id + '/subAssets');
      for (const index in childAssets) {
        await this.addAssetandSubAsset(await this._httpService.get('inventory/assets/' + childAssets[index].subAssetId), false);
      }
    }
  }

  async deleteBundleAsset(id: number) {
    this.loading = true;
    id = id[0];
    const deletingAsset = this.bundleAssets.find(c => c.id === id);
    if (deletingAsset.parentAsset !== null) {
      this.openErrorDialog(`Cannot remove Asset ${deletingAsset.alais} because it is a child of asset ${deletingAsset.parentAsset}. Remove the parent to remove this asset.`);
      this.loading = false;
    } else {
      await this.deleteAssetAndSubAsset(deletingAsset);
      this.load();
    }
  }

  async deleteAssetAndSubAsset(assetToDelete, checkSubAsset = true) {
    assetToDelete.bundleId = null;
    assetToDelete.status = 'Available';
    assetToDelete.warehouseId = this.bundle.warehouseId;
    if (assetToDelete.warehouseLocation == null) {
      assetToDelete.warehouseLocation = 'UNKNOWN - REMOVED FROM FULFILLMENT';
    }

    await this._httpService.put('inventory/assets', assetToDelete);
    if (checkSubAsset) {
      const childAssets = await this._httpService.get('inventory/assets/' + assetToDelete.id + '/subAssets');
      for (const index in childAssets) {
        await this.deleteAssetAndSubAsset(await this._httpService.get('inventory/assets/' + childAssets[index].subAssetId), false);
      }
    }
  }

  openConsumable(id) {
    const bundleConsumable = this.bundleConsumables.find(c => c.id === id);
    this._router.navigateByUrl('/inventory/consumables/' + bundleConsumable.consumableId);
  }

  openAsset(id) {
    this._router.navigateByUrl('/inventory/assets/' + id);
  }

  async openErrorDialog(errorMessage) {
    this.errorMessage = errorMessage;
    this._dialog.open(this.errorDialog);
  }

}
